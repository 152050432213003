import React, { useContext } from "react";
import {
  StyleSheet,
  StyleProp,
  View,
  TouchableOpacity,
  ViewStyle
} from "react-native";
import { ThemeContext } from "../context/ThemeContext";
import { Icon } from "react-native-elements";
import AppText from "./text/AppText";
import useScale, { Scales } from "../hooks/useScale";
import { VPayColors } from "../types/appTypes";
import { MEDIUM_FONT, FONT_SIZE_BASE_LITE } from "../constants/StyleConstants";

type SortableDetailHeaderProps = {
  inputStyle?: StyleProp<ViewStyle>;
  text: string | number;
  onPress: (colName: string) => void;
  name: string;
  lastOrd: string;
  asc: boolean;
};

const styles = (colors: VPayColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    headContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center"
    },
    headTitle: {
      ...MEDIUM_FONT,
      color: colors.defaultText,
      fontSize: moderateScale(...FONT_SIZE_BASE_LITE),
      paddingLeft: moderateScale(4, 0.2),
      marginRight: moderateScale(3, 0.2)
    },
    icon: {
      lineHeight: moderateScale(8, 0.2)
    }
  });

const SortableDetailHeader = ({
  text,
  onPress,
  name,
  lastOrd,
  asc,
  inputStyle
}: SortableDetailHeaderProps) => {
  const { colors } = useContext(ThemeContext);
  const scaleFunctions = useScale();
  const orderingSelectedColumn = (icon: string, color?: string) => {
    return (
      <Icon
        size={scaleFunctions.moderateScale(12, 0.2)}
        name={icon}
        type="octicon"
        color={color || colors.bodyHeaderTitleText}
        iconStyle={styles(colors, scaleFunctions).icon}
      />
    );
  };

  return (
    <TouchableOpacity
      style={StyleSheet.flatten([
        styles(colors, scaleFunctions).headContainer,
        inputStyle
      ])}
      onPress={() => {
        onPress(name);
      }}
    >
      <View>
        {lastOrd === name ? (
          <View>
            {orderingSelectedColumn(
              "triangle-up",
              asc ? colors.highText : undefined
            )}
            {orderingSelectedColumn(
              "triangle-down",
              !asc ? colors.highText : undefined
            )}
          </View>
        ) : (
          <View>
            {orderingSelectedColumn("triangle-up")}
            {orderingSelectedColumn("triangle-down")}
          </View>
        )}
      </View>
      <AppText
        style={styles(colors, scaleFunctions).headTitle}
        numberOfLines={1}
        ellipsizeMode="head"
      >
        {text}
      </AppText>
    </TouchableOpacity>
  );
};

export default SortableDetailHeader;
