import React, { useContext } from "react";
import { Platform, View } from "react-native";

import * as scale from "d3-scale";
import { BarChart, YAxis, XAxis, Grid } from "react-native-svg-charts";

import { RulesItem } from "../../types/apiTypes";
import { ThemeContext } from "../../context/ThemeContext";
import useScale from "../../hooks/useScale";
import { ROBOTO } from "../../constants/StyleConstants";

type RulesGraphProps = {
  rulesItems: RulesItem[];
};

const RulesGraph = ({ rulesItems }: RulesGraphProps) => {
  const { colors } = useContext(ThemeContext);
  const { moderateScale } = useScale();

  const labelsFontSize = moderateScale(7.9, 0.3);
  const axesSvg = {
    fontSize: labelsFontSize,
    fill: colors.graphLabels,
    fontFamily: ROBOTO
  };
  const verticalContentInset = { top: 10, bottom: 10 };
  const xAxisHeight = 20;
  const spacingInner = 0.6;
  const spacingOuter = 1;
  const numberOfTicks = 5;

  const getGraphData = () => {
    const graphData: {
      labels: string[];
      dataH: Array<number>;
      dataVH: Array<number>;
    } = { labels: [], dataH: [], dataVH: [] };

    rulesItems.forEach(item => {
      graphData.labels.push(item.ruleId);
      graphData.dataH.push(item.highHits || 0);
      graphData.dataVH.push(item.veryHighHits || 0);
    });
    return graphData;
  };

  const graphDataRes = getGraphData();

  const barData = [
    {
      data: graphDataRes.dataVH.map(value => ({ value })),
      svg: { fill: colors.veryHighBar }
    },
    {
      data: graphDataRes.dataH.map(value => ({ value })),
      svg: { fill: colors.highBar }
    }
  ];

  const renderStackedBarChart = () => {
    return (
      <BarChart
        style={{ flex: 1 }}
        data={barData}
        contentInset={verticalContentInset}
        spacingInner={spacingInner}
        spacingOuter={spacingOuter}
        numberOfTicks={numberOfTicks}
        gridMin={0}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        yAccessor={({ item }) => (item as any).value}
      >
        <Grid svg={{ stroke: "grey" }} />
      </BarChart>
    );
  };

  const renderYAxis = () => {
    return (
      <YAxis
        data={[...graphDataRes.dataH, ...graphDataRes.dataVH]}
        min={0}
        numberOfTicks={numberOfTicks}
        style={{ marginBottom: xAxisHeight, marginHorizontal: 2, minWidth: 20 }}
        contentInset={verticalContentInset}
        svg={axesSvg}
        formatLabel={(value: string) => `${value} %`}
      />
    );
  };

  const renderXAxis = () => {
    return (
      <XAxis
        data={graphDataRes.labels}
        style={{ height: xAxisHeight }}
        spacingInner={spacingInner}
        spacingOuter={spacingOuter}
        scale={scale.scaleBand}
        svg={{
          ...axesSvg,
          rotation: 315,
          dx: -8,
          dy:
            Platform.OS === "web" && navigator.userAgent.includes("Firefox")
              ? labelsFontSize
              : 0
        }}
        formatLabel={(_, index) => graphDataRes.labels[index]}
      />
    );
  };

  return (
    <View
      style={[
        {
          flex: 1,
          flexDirection: "row",
          paddingLeft: 5,
          paddingRight: 10
        }
      ]}
    >
      {renderYAxis()}
      <View style={{ flex: 1 }}>
        {renderStackedBarChart()}
        {renderXAxis()}
      </View>
    </View>
  );
};

export default RulesGraph;
