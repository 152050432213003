import React, { ReactNode } from "react";
import useScale, { Scales } from "../../hooks/useScale";
import { VPayColors } from "../../types/appTypes";
import { View, StyleSheet } from "react-native";
import { BankItem, BankTable } from "../../types/apiTypes";
import { ThemeContext } from "../../context/ThemeContext";
import settingsStore from "../../stores/settingsStore";
import {
  NO_DATA_NUMBER,
  TABLE_LINE_HEIGHT_ARGS
} from "../../constants/AppConstants";
import {
  Cell,
  Row,
  Table,
  TableWrapper
} from "@deb-95/react-native-table-component";
import { formatPercentages, formattingCount } from "../../utils/num_formatting";
import {
  BANKS_TRANSACTIONS,
  RANK_TRANS,
  bankComposedLabel,
  ALL_BANKS_PREFIX,
  BANKS_HEAD_TITLE
} from "../../constants/Dictionary";
import TableDescendingSortHeader from "../TableDescendingSortHeader";
import GeographyIconName from "../GeographyIconName";
import AppText from "../text/AppText";
import {
  BOLD_FONT,
  ROBOTO,
  FONT_SIZE_BASE
} from "../../constants/StyleConstants";
import { Platform } from "react-native";

const flexArrHeader = [2.4, 1.2, 1.05, 0.85];
const flexArrFirstRow = [2.5, 1.2, 1, 0.8];
const flexArrBody = [0.5, 2, 1.2, 1, 0.8];
const NO_DATA_ROW = [
  [
    NO_DATA_NUMBER,
    NO_DATA_NUMBER,
    NO_DATA_NUMBER,
    NO_DATA_NUMBER,
    NO_DATA_NUMBER
  ]
];

const styles = (colors: VPayColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    cellLeft: {
      textAlign: "left",
      paddingLeft: moderateScale(8, 0.2)
    },
    cellRight: {
      textAlign: "right",
      paddingRight: moderateScale(8, 0.2)
    },
    textStyles: {
      color: colors.defaultText,
      fontSize: moderateScale(...FONT_SIZE_BASE),
      fontFamily: ROBOTO
    },
    row: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: colors.tableRowBorder,
      backgroundColor: colors.oddValue,
      height: moderateScale(...TABLE_LINE_HEIGHT_ARGS)
    },
    tableHeaderText: {
      color: colors.defaultText,
      fontSize: moderateScale(...FONT_SIZE_BASE),
      textAlign: "left",
      paddingLeft: moderateScale(5, 0.2),
      ...BOLD_FONT
    },
    veryHighHits: {
      color: colors.veryHighText,
      fontSize: moderateScale(...FONT_SIZE_BASE)
    },
    highHits: {
      color: colors.highText,
      fontSize: moderateScale(...FONT_SIZE_BASE)
    },
    headRow: {
      height: moderateScale(...TABLE_LINE_HEIGHT_ARGS)
    }
  });

type BanksTableProps = {
  banksData: BankTable;
  selectedLanguage: string;
  onTitlePress: (id: string) => void;
  selectedGeoFilter: string;
  fieldOrderedBy: keyof BankItem;
};

const BanksTable = ({
  banksData,
  selectedLanguage,
  onTitlePress,
  selectedGeoFilter,
  fieldOrderedBy
}: BanksTableProps) => {
  const { colors } = React.useContext(ThemeContext);
  const tableBody: (string | ReactNode)[][] = [];
  const scaleFunctions = useScale();
  const geo = settingsStore.getGeoFilter(selectedGeoFilter)?.labelLong[
    selectedLanguage
  ];
  const tableHead = banksData.head;
  const tableHeader = [
    <GeographyIconName
      key={"geographyIcon"}
      text={geo}
      iconUrl={tableHead.url}
      additionalContainerStyle={{
        paddingLeft: scaleFunctions.moderateScale(5, 0.2)
      }}
    />,
    <TableDescendingSortHeader
      key={"headerTransactions"}
      textStyle={styles(colors, scaleFunctions).tableHeaderText}
      text={BANKS_TRANSACTIONS[selectedLanguage]}
      onPress={() => onTitlePress("count")}
      isSorted={fieldOrderedBy === "count"}
    />,
    <TableDescendingSortHeader
      key={"headerVeryHigh"}
      textStyle={[
        styles(colors, scaleFunctions).tableHeaderText,
        styles(colors, scaleFunctions).veryHighHits
      ]}
      text={RANK_TRANS.VERY_HIGH[selectedLanguage]}
      onPress={() => onTitlePress("veryHighHits")}
      isSorted={fieldOrderedBy === "veryHighHits"}
    />,
    <TableDescendingSortHeader
      key={"headerHigh"}
      textStyle={[
        styles(colors, scaleFunctions).tableHeaderText,
        styles(colors, scaleFunctions).highHits,
        styles(colors, scaleFunctions).cellRight
      ]}
      text={RANK_TRANS.HIGH[selectedLanguage]}
      onPress={() => onTitlePress("highHits")}
      isSorted={fieldOrderedBy === "highHits"}
    />
  ];

  const firstRow: ReactNode[] = [
    <AppText
      key={"geoFilter"}
      style={[
        styles(colors, scaleFunctions).tableHeaderText,
        styles(colors, scaleFunctions).cellLeft
      ]}
    >
      {selectedGeoFilter === "ALL"
        ? `${ALL_BANKS_PREFIX[selectedLanguage]} ${BANKS_HEAD_TITLE[selectedLanguage]}`
        : bankComposedLabel(selectedGeoFilter)[selectedLanguage]}
    </AppText>,
    <AppText
      key={"tableHeadCount"}
      style={[
        styles(colors, scaleFunctions).tableHeaderText,
        styles(colors, scaleFunctions).cellRight
      ]}
    >
      {formattingCount(tableHead.count, selectedLanguage)}
    </AppText>,
    <AppText
      key={"veryHighFirstRow"}
      style={[
        styles(colors, scaleFunctions).tableHeaderText,
        styles(colors, scaleFunctions).veryHighHits,
        styles(colors, scaleFunctions).cellRight
      ]}
    >
      {formattingCount(tableHead.veryHighCount, selectedLanguage)}
    </AppText>,
    <AppText
      key={"highFirstRow"}
      style={[
        styles(colors, scaleFunctions).tableHeaderText,
        styles(colors, scaleFunctions).highHits,
        styles(colors, scaleFunctions).cellRight
      ]}
    >
      {formattingCount(tableHead.highCount, selectedLanguage)}
    </AppText>
  ];

  banksData.items.forEach((item: BankItem, idx) =>
    tableBody.push([
      (idx + 1).toString(),
      <AppText
        key={"bankNameBody" + idx}
        style={styles(colors, scaleFunctions).textStyles}
        numberOfLines={2}
        ellipsizeMode={"tail"}
      >
        {item.bankLabel[selectedLanguage]}
      </AppText>,
      formattingCount(item.count, selectedLanguage),
      formatPercentages(item.veryHighHits, selectedLanguage),
      formatPercentages(item.highHits, selectedLanguage)
    ])
  );

  const renderRowCell = (item: string | ReactNode, idx: number) => (
    <Cell
      key={"calTable" + idx}
      data={item}
      textStyle={[styles(colors, scaleFunctions).textStyles]}
      style={[
        {
          flex: flexArrBody[idx],
          backgroundColor: idx % 2 === 0 ? colors.oddValue : colors.evenValue
        },
        idx > 1
          ? styles(colors, scaleFunctions).cellRight
          : styles(colors, scaleFunctions).cellLeft
      ]}
    />
  );

  const renderRow = (row: (string | ReactNode)[], index: number) => (
    <TableWrapper
      key={"tableWrapperRule" + index}
      style={[styles(colors, scaleFunctions).row]}
    >
      {row.map((item, idx) => renderRowCell(item, idx))}
    </TableWrapper>
  );

  return (
    <Table style={{ flex: 1 }}>
      <Row
        data={tableHeader}
        flexArr={flexArrHeader}
        style={[
          styles(colors, scaleFunctions).headRow,
          { backgroundColor: colors.tableHeader }
        ]}
      />
      <Row
        data={firstRow}
        flexArr={flexArrFirstRow}
        style={[
          styles(colors, scaleFunctions).headRow,
          { backgroundColor: colors.oddHeader }
        ]}
      />
      <View
        // Typescript is complaining because "auto" does not exist in Native but
        // by checking the platform before using it we can ensure it works.
        style={{ flex: 1, overflow: Platform.OS === "web" ? "auto" : "scroll" }}
      >
        {banksData.items.length === 0
          ? NO_DATA_ROW.map((item, idx) => renderRow(item, idx))
          : tableBody.map((item, idx) => renderRow(item, idx))}
      </View>
    </Table>
  );
};

export default BanksTable;
