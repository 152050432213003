import React, { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import { View, Button, Platform, StyleSheet } from "react-native";
import AppText from "../components/text/AppText";
import settingsStore from "../stores/settingsStore";
import { RELOAD } from "../constants/Dictionary";
import useScale, { Scales } from "../hooks/useScale";
import { VPayColors } from "../types/appTypes";
import { FONT_SIZE_BASE } from "../constants/StyleConstants";

const errorScreenStyles = (
  colors: VPayColors,
  { moderateScale, scale }: Scales
) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.screenBackgroundColor,
      alignItems: "center",
      justifyContent: "center"
    },
    text: {
      color: colors.defaultText,
      textAlign: "center"
    },
    lateralPadding: {
      paddingHorizontal: scale(35)
    },
    titleText: {
      fontSize: moderateScale(14, 0.2),
      paddingBottom: moderateScale(14, 0.2)
    },
    detailText: {
      fontSize: moderateScale(...FONT_SIZE_BASE),
      paddingBottom: moderateScale(12, 0.2)
    }
  });

type ErrorScreenProps = { errorMessage: string; msgDetail: string };

const ErrorScreen = ({ errorMessage, msgDetail }: ErrorScreenProps) => {
  const { colors } = useContext(ThemeContext);
  const scaleFunctions = useScale();
  return (
    <View style={errorScreenStyles(colors, scaleFunctions).container}>
      <AppText
        style={[
          errorScreenStyles(colors, scaleFunctions).text,
          errorScreenStyles(colors, scaleFunctions).titleText,
          errorScreenStyles(colors, scaleFunctions).lateralPadding
        ]}
      >
        {errorMessage}
      </AppText>
      <AppText
        style={[
          errorScreenStyles(colors, scaleFunctions).text,
          errorScreenStyles(colors, scaleFunctions).detailText,
          errorScreenStyles(colors, scaleFunctions).lateralPadding
        ]}
      >
        {msgDetail}
      </AppText>
      <Button
        title={RELOAD[settingsStore.getSelectedLanguage()]}
        onPress={() => {
          if (Platform.OS !== "web") {
            // find a way to restart in native
          } else {
            location.reload(true);
          }
        }}
      />
    </View>
  );
};

export default ErrorScreen;
