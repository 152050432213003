import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { ThemeContext } from "../context/ThemeContext";
import Filters from "../components/Filters";
import settingsStore from "../stores/settingsStore";
import { useRoute } from "@react-navigation/native";
import { RankEnum } from "../constants/AppConstants";
import RiskService from "../services/RiskService";
import { RiskDetailData, Response } from "../types/apiTypes";
import RiskDetailBody from "../components/RiskDetailBody";
import { SettingsState } from "../types/storesTypes";
import TimeBar from "../components/TimeBar";
import LoadingView from "../components/LoadingView";
import useSettingsState from "../hooks/useSettingsState";
import { VPayColors } from "../types/appTypes";

const riskStyle = (colors: VPayColors) =>
  StyleSheet.create({
    riskScreen: {
      flex: 1,
      backgroundColor: colors.screenBackgroundColor
    }
  });

export default function RiskDetailScreen(): JSX.Element {
  const { colors } = React.useContext(ThemeContext);
  const route = useRoute();
  const { riskRank, settingsStateParam } = route.params as {
    riskRank: RankEnum;
    settingsStateParam: SettingsState;
  };
  const [riskDetailApi, setRiskDetailApi] = useState<RiskDetailData>();
  const settingsState = useSettingsState(settingsStateParam);

  const handleRiskCall = (riskResponse: RiskDetailData) => {
    setRiskDetailApi(riskResponse);
    settingsStore.setLoadingGauge(100);
  };

  useEffect(() => {
    settingsStore.setLoadingGauge(30);
    const riskDetailApiSubscription = RiskService.callRiskDetail({
      geo: settingsState.selectedGeoFilter,
      time: settingsState.selectedTimeFilter,
      rank: riskRank,
      realTime: settingsState.realTime
    }).subscribe(handleRiskCall);
    return () => {
      riskDetailApiSubscription.unsubscribe();
    };
  }, [
    settingsState.selectedGeoFilter,
    settingsState.selectedTimeFilter,
    settingsState.realTime,
    riskRank
  ]);

  return (
    <View style={riskStyle(colors).riskScreen}>
      <View style={{ paddingBottom: 10 }}>
        <Filters settingsState={settingsState} />
      </View>
      {riskDetailApi ? (
        <View style={{ flex: 1 }}>
          <TimeBar
            settingsState={settingsState}
            apiData={riskDetailApi as Response}
          />
          <RiskDetailBody
            settingsState={settingsState}
            riskDetailApi={riskDetailApi as RiskDetailData}
            riskRank={riskRank}
          />
        </View>
      ) : (
        <LoadingView />
      )}
    </View>
  );
}
