import React from "react";
import { ThemeContext } from "../context/ThemeContext";
import { Icon } from "react-native-elements";
import useScale from "../hooks/useScale";

type TabBarIconProps = {
  focused: boolean;
  name: string;
  type: string;
  size?: number;
};

export default function TabBarIcon(props: TabBarIconProps) {
  const { colors } = React.useContext(ThemeContext);
  const scales = useScale();

  return (
    <Icon
      name={props.name}
      type={props.type}
      size={props.size || scales.moderateScale(26, 0.1)}
      color={props.focused ? colors.tabIconSelected : colors.tabIconDefault}
    />
  );
}
