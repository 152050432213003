/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Icon } from "react-native-elements";
import { ThemeContext } from "../context/ThemeContext";

export const MenuIcon = ({ navigation }: any) => {
  const { colors } = React.useContext(ThemeContext);
  return (
    <Icon
      name="menu"
      size={30}
      style={{ paddingLeft: 10 }}
      color={colors.defaultText}
      onPress={() => navigation.toggleDrawer()}
    />
  );
};
