import React, { useContext } from "react";
import {
  PROGRESS_BAR_LABELS,
  PROGRESS_BAR_LABELS_COLORS_GRADIENT,
  PROGRESS_BAR_COLORS_GRADIENT,
  PROGRESS_BAR_LABELS_COLORS
} from "../constants/AppConstants";
import RiskTable from "./RiskTable";
import { RiskOverviewData } from "../types/apiTypes";
import { SettingsState } from "../types/storesTypes";
import { View } from "react-native";
import OverviewGraph from "./RiskOverviewGraph";
import RiskKpiSwitch from "./RiskKpiSwitch";
import { ThemeContext } from "../context/ThemeContext";
import NoDataView from "./NoDataView";
import RiskOverviewGraphWithNeedle from "./RiskOverviewGraphWithNeedle";
import useScale from "../hooks/useScale";
import { GRAPH_CONTAINER_HEIGHT } from "../constants/StyleConstants";

type RiskBodyProps = {
  riskApi: RiskOverviewData;
  settingsState: SettingsState;
};

const RiskBody = ({ riskApi, settingsState }: RiskBodyProps) => {
  const { darkMode } = useContext(ThemeContext);
  const scales = useScale();
  const renderGraph = () => {
    return darkMode ? (
      <OverviewGraph
        riskColors={PROGRESS_BAR_COLORS_GRADIENT}
        colorsGradientLocations={[0, 0.2, 0.4, 0.6, 0.65, 0.8, 0.95]}
        labelsColors={PROGRESS_BAR_LABELS_COLORS_GRADIENT}
        labels={PROGRESS_BAR_LABELS}
        value={riskApi.graphRisk * 100}
        settingsState={settingsState}
      />
    ) : (
      <RiskOverviewGraphWithNeedle
        riskColors={PROGRESS_BAR_COLORS_GRADIENT}
        colorsGradientLocations={[0, 0.2, 0.4, 0.6, 0.65, 0.8, 0.95]}
        labelsColors={PROGRESS_BAR_LABELS_COLORS}
        labels={PROGRESS_BAR_LABELS}
        value={riskApi.graphRisk * 100}
        settingsState={settingsState}
      />
    );
  };
  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          flex: 1,
          maxHeight: scales.moderateScale(...GRAPH_CONTAINER_HEIGHT)
        }}
      >
        <RiskKpiSwitch settingsState={settingsState} />
        {riskApi.graphRisk ? (
          renderGraph()
        ) : (
          <NoDataView settingsState={settingsState} />
        )}
      </View>
      <View style={{ flex: 1 }}>
        <RiskTable riskApi={riskApi} settingsState={settingsState} />
      </View>
    </View>
  );
};

export default RiskBody;
