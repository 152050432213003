import React, { useState } from "react";
import { View } from "react-native";
import { RulesItem, RulesTable } from "../../types/apiTypes";
import { SettingsState } from "../../types/storesTypes";
import NoDataView from "../NoDataView";
import { RulesTableComponent } from "./RulesTableComponent";
import { RulesTransactionCount } from "./RulesTransactionCount";
import RulesGraph from "./RulesDetailGraph";
import settingsStore from "../../stores/settingsStore";
import { RULES_MAX_GRAPH_ELEMENTS } from "../../constants/AppConstants";
import { GRAPH_CONTAINER_HEIGHT } from "../../constants/StyleConstants";
import useScale from "../../hooks/useScale";
import { NO_RISK_TO_SHOW } from "../../constants/Dictionary";

type RulesBodyProps = {
  rulesData: RulesTable;
  settingsState: SettingsState;
};

const orderMap = {
  ruleId: "asc",
  sort: "asc", // ruleLabel is not sorted by comparing ruleLabel fields as in all the other cases but with the SORT field
  veryHighHits: "desc",
  highHits: "desc"
};

const RulesBody = ({ rulesData, settingsState }: RulesBodyProps) => {
  const [fieldOrderedBy, setFieldOrderedBy] = useState<keyof RulesItem>(
    "veryHighHits"
  );
  const { moderateScale } = useScale();

  const onTitlePress = (colName: string) => {
    if (colName === "ruleLabel") {
      setFieldOrderedBy("sort" as keyof RulesItem);
    } else if (colName !== fieldOrderedBy) {
      setFieldOrderedBy(colName as keyof RulesItem);
    }
  };

  const orderedData = [...rulesData.items].sort((a, b) => {
    const firstEl = a[fieldOrderedBy];
    const secondEl = b[fieldOrderedBy];
    if (orderMap[fieldOrderedBy] === "asc") {
      return firstEl > secondEl ? 1 : -1;
    } else {
      return firstEl > secondEl ? -1 : 1;
    }
  });

  const geo = settingsStore.getGeoFilter(settingsState.selectedGeoFilter)
    ?.labelLong[settingsState.selectedLanguage];

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          flex: 1,
          maxHeight: moderateScale(...GRAPH_CONTAINER_HEIGHT)
        }}
      >
        {rulesData.items.length > 0 ? (
          <RulesGraph
            rulesItems={orderedData.slice(0, RULES_MAX_GRAPH_ELEMENTS)}
          />
        ) : (
          <NoDataView
            settingsState={settingsState}
            text={NO_RISK_TO_SHOW[settingsState.selectedLanguage]}
          />
        )}
      </View>
      <View style={{ flex: 1 }}>
        <RulesTransactionCount
          rulesHead={rulesData.head}
          selectedLanguage={settingsState.selectedLanguage}
          selectedGeography={geo}
        />
        <RulesTableComponent
          rulesItems={orderedData}
          onTitlePress={onTitlePress}
          selectedLanguage={settingsState.selectedLanguage}
          fieldOrderedBy={fieldOrderedBy}
        />
      </View>
    </View>
  );
};

export default RulesBody;
