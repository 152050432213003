import { useEffect, EffectCallback } from "react";
/**
 * Executes the input function only on component mount instead of every update by passing an empty array as
 * a dependency parameter.
 * This is needed for example when subscribing to a store, since we only want to do it once and not
 * every time the component updates.
 * @param fun function you would usually pass to useEffect
 */
const useMountEffect = (fun: EffectCallback) => {
  useEffect(fun, []);
};

export default useMountEffect;
