import React from "react";
import { View, StyleSheet } from "react-native";
import { SettingsState } from "../types/storesTypes";
import { RiskKpiEnum } from "../constants/AppConstants";
import RiskKpiSwitchElement from "./RiskKpiSwitchElement";
import { KPI_NAMES } from "../constants/Dictionary";
import AppText from "./text/AppText";

const riskKpiSwitchStyle = StyleSheet.create({
  container: {
    marginTop: 10,
    paddingLeft: 10,
    flexDirection: "row",
    alignItems: "center"
  }
});

type RiskKpiSwitchProps = {
  settingsState: SettingsState;
};

export default function RiskKpiSwitch({
  settingsState
}: RiskKpiSwitchProps): JSX.Element {
  return (
    <View style={riskKpiSwitchStyle.container}>
      <RiskKpiSwitchElement
        switchId={RiskKpiEnum.COUNT}
        textContent={`${
          KPI_NAMES[RiskKpiEnum.COUNT][settingsState.selectedLanguage]
        }`}
        selectedKpiFilter={settingsState.selectedKpiFilter}
      />
      <AppText> | </AppText>
      <RiskKpiSwitchElement
        switchId={RiskKpiEnum.AMOUNT}
        textContent={`${
          KPI_NAMES[RiskKpiEnum.AMOUNT][settingsState.selectedLanguage]
        }`}
        selectedKpiFilter={settingsState.selectedKpiFilter}
      />
    </View>
  );
}
