import React from "react";
import { View, StyleProp, ViewStyle, StyleSheet } from "react-native";
import AppText from "./text/AppText";
import { Label } from "../types/apiTypes";
import useScale, { Scales } from "../hooks/useScale";

type LegendItemProps = {
  color: string;
  label: string;
};

const legendItemStyles = ({ moderateScale }: Scales) =>
  StyleSheet.create({
    square: {
      width: moderateScale(10, 0.2),
      height: moderateScale(10, 0.2),
      marginHorizontal: 5
    }
  });
const LegendItem = ({ color, label }: LegendItemProps) => {
  const scaleFunctions = useScale();
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingHorizontal: 10
      }}
    >
      <View
        style={[
          legendItemStyles(scaleFunctions).square,
          { backgroundColor: color }
        ]}
      />
      <AppText style={{ fontSize: scaleFunctions.moderateScale(10, 0.2) }}>
        {label}
      </AppText>
    </View>
  );
};

type GraphLegendProps = {
  language: string;
  additionalStyle: StyleProp<ViewStyle>;
  graphColors: string[];
  legendLabels: Label[];
};

const GraphLegend = ({
  language,
  additionalStyle,
  graphColors,
  legendLabels
}: GraphLegendProps) => {
  return (
    <View
      style={[
        {
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        },
        additionalStyle
      ]}
    >
      {graphColors.map((color, index) => (
        <LegendItem
          key={color}
          color={color}
          label={legendLabels[index][language]}
        />
      ))}
    </View>
  );
};

export default GraphLegend;
