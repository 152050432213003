import React, { useState } from "react";
import { View } from "react-native";
import { BankTable, BankItem } from "../../types/apiTypes";
import { SettingsState } from "../../types/storesTypes";
import BanksTable from "./BanksTable";
import NoDataView from "../NoDataView";
import BanksGraph from "./BanksGraph";
import { BANKS_MAX_GRAPH_ELEMENTS } from "../../constants/AppConstants";
import { GRAPH_CONTAINER_HEIGHT } from "../../constants/StyleConstants";
import useScale from "../../hooks/useScale";
import { NO_RISK_TO_SHOW } from "../../constants/Dictionary";

type RulesBodyProps = {
  banksData: BankTable;
  settingsState: SettingsState;
};

const BanksBody = ({ banksData, settingsState }: RulesBodyProps) => {
  const [fieldOrderedBy, setFieldOrderedBy] = useState<keyof BankItem>("count");
  const { moderateScale } = useScale();

  const onTitlePress = (colName: string) => {
    if (colName !== fieldOrderedBy) {
      setFieldOrderedBy(colName as keyof BankItem);
    }
  };

  const renderGraph = () => {
    if (banksData.items.length === 0) {
      return <NoDataView settingsState={settingsState} />;
    } else if (
      banksData.items.every(item => !item.veryHighHits && !item.highHits)
    ) {
      return (
        <NoDataView
          settingsState={settingsState}
          text={NO_RISK_TO_SHOW[settingsState.selectedLanguage]}
        />
      );
    } else {
      return (
        <BanksGraph
          banksItems={banksData.items.slice(0, BANKS_MAX_GRAPH_ELEMENTS)}
        />
      );
    }
  };

  banksData.items.sort((a, b) => {
    const firstEl = a[fieldOrderedBy];
    const secondEl = b[fieldOrderedBy];
    return firstEl > secondEl ? -1 : 1;
  });

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          flex: 1,
          maxHeight: moderateScale(...GRAPH_CONTAINER_HEIGHT)
        }}
      >
        {renderGraph()}
      </View>
      <BanksTable
        banksData={banksData}
        onTitlePress={onTitlePress}
        selectedLanguage={settingsState.selectedLanguage}
        selectedGeoFilter={settingsState.selectedGeoFilter}
        fieldOrderedBy={fieldOrderedBy}
      />
    </View>
  );
};

export default BanksBody;
