import React, { useContext } from "react";

import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { DrawerScreen } from "./Drawer";
import { ThemeContext } from "../context/ThemeContext";

export default function AppNavigator() {
  const { darkMode, colors } = useContext(ThemeContext);

  return (
    <NavigationContainer
      documentTitle={{ enabled: false }}
      theme={{
        dark: darkMode,
        colors: {
          ...DefaultTheme.colors,
          card: colors.screenBackgroundColor,
          background: colors.screenBackgroundColor,
          border: colors.screenBackgroundColor,
          text: colors.defaultText
        }
      }}
    >
      <DrawerScreen />
    </NavigationContainer>
  );
}
