import { Platform } from "react-native";
import { LATERAL_DESKTOP_VIEW_PADDING_WIDTH } from "./../constants/StyleConstants";
import useLayout from "./useLayout";

export type ScaleWithFactor = (size: number, factor?: number) => number;
export type Scale = (size: number) => number;
export type Scales = {
  scale: Scale;
  verticalScale: Scale;
  moderateScale: ScaleWithFactor;
  moderateVerticalScale: ScaleWithFactor;
  s: Scale;
  vs: Scale;
  ms: ScaleWithFactor;
  mvs: ScaleWithFactor;
};

const MOBILE_GUIDE_LINE_BASE_WIDTH = 350;
const MOBILE_GUIDE_LINE_BASE_HEIGHT = 680;

export type ScaleFunctionArgs = [number, number?];

const useScale = (): Scales => {
  const { width, height } = useLayout();
  const widthReal =
    Platform.OS === "web" && width >= 1280
      ? width - LATERAL_DESKTOP_VIEW_PADDING_WIDTH * 2
      : width;
  const [shortDimension, longDimension] =
    widthReal < height ? [widthReal, height] : [height, widthReal];

  const scale = (size: number) =>
    (shortDimension / MOBILE_GUIDE_LINE_BASE_WIDTH) * size;
  const verticalScale = (size: number) =>
    (longDimension / MOBILE_GUIDE_LINE_BASE_HEIGHT) * size;
  const moderateScale = (size: number, factor = 0.5) =>
    size + (scale(size) - size) * factor;
  const moderateVerticalScale = (size: number, factor = 0.5) =>
    size + (verticalScale(size) - size) * factor;
  const s = scale;
  const vs = verticalScale;
  const ms = moderateScale;
  const mvs = moderateVerticalScale;
  return {
    scale,
    verticalScale,
    moderateScale,
    moderateVerticalScale,
    s,
    vs,
    ms,
    mvs
  };
};

export default useScale;
