/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Icon } from "react-native-elements";
import { HOME } from "../constants/AppConstants";
import { ThemeContext } from "../context/ThemeContext";

export const CloseMenuIcon = ({ navigation }: any) => {
  const { colors } = React.useContext(ThemeContext);

  return (
    <Icon
      name="close"
      style={{ paddingLeft: 10 }}
      size={30}
      color={colors.defaultText}
      onPress={() => navigation.navigate(HOME)}
    />
  );
};
