import * as React from "react";
import { StyleSheet, ImageURISource } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Avatar, Icon } from "react-native-elements";
import settingsStore from "../stores/settingsStore";
import { ThemeContext } from "../context/ThemeContext";
import AppText from "./text/AppText";
import { VPayColors } from "../types/appTypes";

type LanguageItemProps = {
  label: string;
  id: string;
  isSelected: boolean;
};

const FLAGS: { [key: string]: ImageURISource } = {
  it: require(`../../assets/images/flags/it.png`),
  en: require(`../../assets/images/flags/en.png`)
};

const languageItemStyles = (colors: VPayColors) =>
  StyleSheet.create({
    container: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      padding: 5,
      backgroundColor: colors.screenBackgroundColor,
      alignItems: "center"
    },
    text: {
      color: colors.defaultText,
      paddingHorizontal: 5
    },
    selectedLanguageText: {
      fontWeight: "bold"
    }
  });

const LanguageItem = ({ label, id, isSelected }: LanguageItemProps) => {
  const _onPress = (languageId: string) => {
    settingsStore.changeLanguage(languageId);
  };

  const { colors } = React.useContext(ThemeContext);

  return (
    <TouchableOpacity
      style={[languageItemStyles(colors).container]}
      onPress={() => _onPress(id)}
    >
      <Avatar size="small" source={FLAGS[id]} />
      <AppText
        style={[
          languageItemStyles(colors).text,
          isSelected && languageItemStyles(colors).selectedLanguageText
        ]}
      >
        {label}
      </AppText>
      {isSelected && <Icon name="check" color={colors.defaultText} />}
    </TouchableOpacity>
  );
};

export default LanguageItem;
