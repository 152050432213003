import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { APPINSIGHTS_KEY } from "../apis/ApiBaseSettings";
import { User } from "../types/storesTypes";

interface AppTrackingModel {
  setAppTracking(user: User): ApplicationInsights | undefined;
}

class AppTrackingService implements AppTrackingModel {
  public setAppTracking(user: User): ApplicationInsights | undefined {
    const appInsightsKey = APPINSIGHTS_KEY;

    if (!appInsightsKey) {
      return;
    }

    // config options used to initialize app insights
    const AppInsights = new ApplicationInsights({
      config: {
        instrumentationKey: APPINSIGHTS_KEY,
        isStorageUseDisabled: true,
        isCookieUseDisabled: true,
        disableExceptionTracking: true
      }
    });

    AppInsights.loadAppInsights();

    AppInsights.addTelemetryInitializer(envelope => {
      const telemetryItem =
        envelope.data !== undefined && envelope.data.baseData;
      telemetryItem.properties = telemetryItem.properties || {};
      telemetryItem.properties.screenSize = `${window.screen.width}x${window.screen.height}`;
      telemetryItem.properties.screenResolution = window.devicePixelRatio.toString();
    });

    AppInsights.setAuthenticatedUserContext(user.user_id);

    return AppInsights;
  }
}

export default new AppTrackingService();
