import React, { useContext } from "react";
import {
  StyleSheet,
  Text,
  TextStyle,
  StyleProp,
  TextProps
} from "react-native";
import { NORMAL_FONT } from "../../constants/StyleConstants";
import { ThemeContext } from "../../context/ThemeContext";

type AppTextProps = {
  style?: StyleProp<TextStyle>;
  children: string | number;
};

const AppText = (props: AppTextProps & TextProps) => {
  const { style, children } = props;
  const { colors } = useContext(ThemeContext);
  const defaultStyle = { color: colors.defaultText, ...NORMAL_FONT };
  const finalStyle = StyleSheet.flatten([defaultStyle, style]);
  return (
    <Text {...props} style={finalStyle}>
      {children}
    </Text>
  );
};

export default AppText;
