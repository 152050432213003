import * as React from "react";
import { View, StyleSheet, Animated, Easing, Platform } from "react-native";
import { Label } from "../types/apiTypes";
import { SettingsState } from "../types/storesTypes";
import { LinearGradient } from "expo-linear-gradient";
import Colors from "../constants/Colors";
import { ThemeContext } from "../context/ThemeContext";
import AppText from "./text/AppText";
import { OVERVIEW_GRAPH_RISK_LABEL } from "../constants/Dictionary";
import {
  OVERVIEW_CENTER_BORDER_RADIUS_ARGS,
  OVERVIEW_WIDTH_ARGS,
  OVERVIEW_WRAPPER_WIDTH_ARGS
} from "../constants/StyleConstants";
import useScale, { Scales } from "../hooks/useScale";
import { VPayColors } from "../types/appTypes";

const styles = (
  colors: VPayColors,
  { moderateScale, moderateVerticalScale }: Scales
) => {
  const OVERVIEW_WIDTH = moderateVerticalScale(...OVERVIEW_WIDTH_ARGS);
  const OVERVIEW_WRAPPER_WIDTH = moderateVerticalScale(
    ...OVERVIEW_WRAPPER_WIDTH_ARGS
  );
  const OVERVIEW_CENTER_BORDER_RADIUS = moderateVerticalScale(
    ...OVERVIEW_CENTER_BORDER_RADIUS_ARGS
  );

  const OVERVIEW_WRAPPER_HEIGHT = OVERVIEW_WRAPPER_WIDTH / 2;
  const OVERVIEW_WRAPPER_BORDER_RADIUS = OVERVIEW_WRAPPER_WIDTH / 2;
  const OVERVIEW_CENTER_WIDTH = OVERVIEW_WRAPPER_WIDTH - OVERVIEW_WIDTH;
  const OVERVIEW_CENTER_HEIGHT = OVERVIEW_CENTER_WIDTH / 2;
  const OVERVIEW_WRAPPER_WIDTH_FOR_ROTATING_CIRCLE =
    OVERVIEW_WRAPPER_WIDTH + 10;
  const ROTATING_CIRCLE_WIDTH = OVERVIEW_WRAPPER_WIDTH_FOR_ROTATING_CIRCLE;
  const ROTATING_CIRCLE_HEIGHT = ROTATING_CIRCLE_WIDTH / 2;
  const ROTATING_CIRCLE_BORDER_RADIUS = ROTATING_CIRCLE_WIDTH / 2;

  return StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: colors.screenBackgroundColor
    },
    overviewGraphContainer: {
      flexDirection: "row",
      justifyContent: "center",
      backgroundColor: "transparent"
    },
    overviewGraphWrapper: {
      width: OVERVIEW_WRAPPER_WIDTH,
      height: OVERVIEW_WRAPPER_HEIGHT,
      borderTopLeftRadius: OVERVIEW_WRAPPER_BORDER_RADIUS,
      borderTopRightRadius: OVERVIEW_WRAPPER_WIDTH / 2,
      alignSelf: "flex-end",
      ...(Platform.OS === "web" && {
        WebkitMaskImage: "-webkit-radial-gradient(white, black)"
      }) // fixes Safari's bad behaviour with border-radius and overflow hidden properties
    },
    rotatingCircleWrap: {
      position: "absolute",
      left: 0,
      width: OVERVIEW_WRAPPER_WIDTH,
      height: OVERVIEW_WRAPPER_WIDTH / 2,
      top: OVERVIEW_WRAPPER_WIDTH / 2
    },
    rotatingCircle: {
      position: "absolute",
      width: ROTATING_CIRCLE_WIDTH,
      height: ROTATING_CIRCLE_HEIGHT,
      borderRadius: ROTATING_CIRCLE_BORDER_RADIUS,
      top: 0,
      left: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    },
    overviewGraphCenter: {
      display: "flex",
      width: OVERVIEW_CENTER_WIDTH,
      height: OVERVIEW_CENTER_HEIGHT,
      backgroundColor: colors.screenBackgroundColor,
      borderTopLeftRadius: OVERVIEW_CENTER_BORDER_RADIUS,
      borderTopRightRadius: OVERVIEW_CENTER_BORDER_RADIUS,
      position: "absolute",
      flexDirection: "column",
      justifyContent: "center",
      bottom: 0,
      alignSelf: "center",
      overflow: "hidden"
    },
    fontStyle: {
      color: Colors.light.defaultText,
      fontWeight: "bold",
      alignSelf: "center",
      fontSize: moderateScale(11, 0.4)
    }
  });
};

type OverviewGraphProps = {
  labels: Label[];
  riskColors: string[];
  labelsColors: string[];
  colorsGradientLocations?: number[];
  value: number;
  minValue?: number;
  maxValue?: number;
  settingsState: SettingsState;
};

export default function OverviewGraph({
  labels,
  riskColors,
  colorsGradientLocations,
  labelsColors,
  value,
  minValue,
  maxValue,
  settingsState
}: OverviewGraphProps) {
  const min = minValue || 0;
  const max = maxValue || 100;
  const [valueState] = React.useState(new Animated.Value(value));
  const scaleFunctions = useScale();

  const OVERVIEW_WRAPPER_WIDTH_FOR_ROTATING_CIRCLE =
    scaleFunctions.moderateVerticalScale(...OVERVIEW_WRAPPER_WIDTH_ARGS) + 10;

  Animated.timing(valueState, {
    toValue: value,
    duration: 300,
    easing: Easing.linear,
    useNativeDriver: false
  }).start();

  const progressRotation = valueState.interpolate({
    inputRange: [min, max],
    outputRange: ["180deg", "360deg"]
  });

  const indexForLabelAndColor = Math.floor(value / ((max + 1) / labels.length));
  const { colors } = React.useContext(ThemeContext);
  return (
    <View style={[styles(colors, scaleFunctions).container]}>
      <View style={[styles(colors, scaleFunctions).overviewGraphContainer]}>
        <LinearGradient
          colors={riskColors}
          locations={colorsGradientLocations}
          start={[0, 0]}
          end={[1, 0]}
          style={[styles(colors, scaleFunctions).overviewGraphWrapper]}
        >
          <View style={[styles(colors, scaleFunctions).rotatingCircleWrap]}>
            <Animated.View
              style={[
                styles(colors, scaleFunctions).rotatingCircle,
                {
                  backgroundColor: "rgba(0,0,0,0.7)",
                  transform: [
                    {
                      translateY:
                        -OVERVIEW_WRAPPER_WIDTH_FOR_ROTATING_CIRCLE / 4
                    },
                    {
                      rotate: progressRotation
                    },
                    {
                      translateY: OVERVIEW_WRAPPER_WIDTH_FOR_ROTATING_CIRCLE / 4
                    }
                  ]
                }
              ]}
            />
          </View>
          <View style={styles(colors, scaleFunctions).overviewGraphCenter} />
        </LinearGradient>
      </View>
      <View style={{ marginTop: scaleFunctions.moderateVerticalScale(3, 0.3) }}>
        <AppText
          style={{
            fontSize: scaleFunctions.moderateVerticalScale(12, 0.2),
            alignSelf: "center"
          }}
        >
          {OVERVIEW_GRAPH_RISK_LABEL[settingsState.selectedLanguage]}
        </AppText>
        <View
          style={{
            marginTop: 3,
            paddingHorizontal: scaleFunctions.moderateVerticalScale(20, 0.4),
            paddingVertical: 2,
            backgroundColor: labelsColors[indexForLabelAndColor]
          }}
        >
          <AppText style={[styles(colors, scaleFunctions).fontStyle]}>
            {labels[indexForLabelAndColor][
              settingsState.selectedLanguage
            ]?.toUpperCase()}
          </AppText>
        </View>
      </View>
    </View>
  );
}
