import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import settingsStore from "../stores/settingsStore";
import TimeBar from "../components/TimeBar";
import Filters from "../components/Filters";
import RiskService from "../services/RiskService";
import { RiskOverviewData, Response } from "../types/apiTypes";
import RiskBody from "../components/RiskBody";
import { ThemeContext } from "../context/ThemeContext";
import LoadingView from "../components/LoadingView";
import useSettingsState from "../hooks/useSettingsState";
import { VPayColors } from "../types/appTypes";

const riskStyle = (colors: VPayColors) =>
  StyleSheet.create({
    riskScreen: {
      flex: 1,
      backgroundColor: colors.screenBackgroundColor
    }
  });

export default function RiskScreen(): JSX.Element {
  const settingsState = useSettingsState(settingsStore.default);
  const [riskApi, setRiskApi] = useState<RiskOverviewData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { colors } = React.useContext(ThemeContext);

  const handleRiskCall = (riskResponse: RiskOverviewData) => {
    setRiskApi(riskResponse);
    settingsStore.setLoadingGauge(100);
    setIsLoading(false);
  };

  // making a new call every time a filter updates
  useEffect(() => {
    if (settingsState.selectedGeoFilter && settingsState.selectedTimeFilter) {
      settingsStore.setLoadingGauge(30);
      settingsState.realTime && setIsLoading(true);
      const riskApiSubscription = RiskService.callRisk({
        geo: settingsState.selectedGeoFilter,
        time: settingsState.selectedTimeFilter,
        realTime: settingsState.realTime
      }).subscribe(handleRiskCall);
      return () => {
        riskApiSubscription.unsubscribe();
      };
    }
  }, [
    settingsState.selectedGeoFilter,
    settingsState.selectedTimeFilter,
    settingsState.realTime
  ]);

  if (isLoading || !settingsState.filters || !riskApi) {
    return <LoadingView />;
  }

  return (
    <View style={riskStyle(colors).riskScreen}>
      <View style={{ paddingBottom: 10 }}>
        <Filters settingsState={settingsState} />
      </View>
      <View style={{ flex: 1 }}>
        <TimeBar settingsState={settingsState} apiData={riskApi as Response} />
        <RiskBody
          settingsState={settingsState}
          riskApi={riskApi as RiskOverviewData}
        />
      </View>
    </View>
  );
}
