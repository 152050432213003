import React from "react";
import { View, StyleSheet } from "react-native";
import Filters from "../components/Filters";
import LoadingView from "../components/LoadingView";
import BanksBody from "../components/banks/BanksBody";
import TimeBar from "../components/TimeBar";
import { ThemeContext } from "../context/ThemeContext";
import useSettingsState from "../hooks/useSettingsState";
import BankService from "../services/BankService";
import settingsStore from "../stores/settingsStore";
import { BankData, Response } from "../types/apiTypes";
import { VPayColors } from "../types/appTypes";

const styles = (colors: VPayColors) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.screenBackgroundColor
    }
  });

const BanksScreen = () => {
  const settingsState = useSettingsState(settingsStore.default);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [banksApi, setBanksApi] = React.useState<BankData>();
  const { colors } = React.useContext(ThemeContext);

  const handleBanksCall = (banksResponse: BankData) => {
    setBanksApi(banksResponse);
    settingsStore.setLoadingGauge(100);
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (settingsState.selectedGeoFilter && settingsState.selectedTimeFilter) {
      settingsStore.setLoadingGauge(30);
      settingsState.realTime && setIsLoading(true);
      const rulesApiSubscription = BankService.callBanks({
        geo: settingsState.selectedGeoFilter,
        time: settingsState.selectedTimeFilter,
        realTime: settingsState.realTime
      }).subscribe(handleBanksCall);
      return () => {
        rulesApiSubscription.unsubscribe();
      };
    }
  }, [
    settingsState.realTime,
    settingsState.selectedGeoFilter,
    settingsState.selectedTimeFilter
  ]);

  if (isLoading || !settingsState.filters || !banksApi) {
    return <LoadingView />;
  }

  return (
    <View style={styles(colors).container}>
      <View style={{ paddingBottom: 10 }}>
        <Filters settingsState={settingsState} />
      </View>
      <View style={{ flex: 1 }}>
        <TimeBar settingsState={settingsState} apiData={banksApi as Response} />
        <BanksBody banksData={banksApi.table} settingsState={settingsState} />
      </View>
    </View>
  );
};

export default BanksScreen;
