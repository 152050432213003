import React, { useContext } from "react";
import { View, StyleSheet, StyleProp, ViewStyle } from "react-native";
import { ThemeContext } from "../context/ThemeContext";
import AppText from "./text/AppText";
import { SettingsState } from "../types/storesTypes";
import { NO_DATA_TO_SHOW } from "../constants/Dictionary";
import { VPayColors } from "../types/appTypes";

const NoDataViewStyle = (colors: VPayColors) =>
  StyleSheet.create({
    container: {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center"
    },
    text: {
      color: colors.defaultText,
      fontStyle: "italic"
    }
  });

type NoDataViewProps = {
  additionalStyle?: StyleProp<ViewStyle>;
  settingsState: SettingsState;
  text?: string;
};

const NoDataView = ({
  additionalStyle,
  settingsState,
  text
}: NoDataViewProps) => {
  const { colors } = useContext(ThemeContext);
  const { selectedLanguage } = settingsState;
  return (
    <View style={[NoDataViewStyle(colors).container, additionalStyle]}>
      <AppText style={NoDataViewStyle(colors).text}>
        {text ? text : NO_DATA_TO_SHOW[selectedLanguage]}
      </AppText>
    </View>
  );
};

export default NoDataView;
