import React from "react";
import { StyleProp, TextStyle, View, ViewStyle } from "react-native";
import { Avatar } from "react-native-elements";
import { MEDIUM_FONT } from "../constants/StyleConstants";
import useScale from "../hooks/useScale";
import AppText from "./text/AppText";

type GeographyIconNameProps = {
  text: string;
  iconUrl: string;
  additionalTextStyle?: StyleProp<TextStyle>;
  additionalContainerStyle?: StyleProp<ViewStyle>;
};

const GeographyIconName: React.FC<GeographyIconNameProps> = ({
  text,
  iconUrl,
  additionalTextStyle,
  additionalContainerStyle
}) => {
  const scaleFunctions = useScale();
  return (
    <View
      style={[
        { flex: 1, flexDirection: "row", alignItems: "center" },
        additionalContainerStyle
      ]}
    >
      <Avatar
        size={scaleFunctions.moderateScale(19, 0.1)}
        rounded
        source={{ uri: iconUrl }}
      />
      <AppText
        style={[
          {
            ...MEDIUM_FONT,
            paddingLeft: scaleFunctions.moderateScale(5, 0.2),
            marginLeft: scaleFunctions.moderateScale(7, 0.2),
            fontSize: scaleFunctions.moderateScale(12, 0.2)
          },
          additionalTextStyle
        ]}
      >
        {text}
      </AppText>
    </View>
  );
};

export default GeographyIconName;
