import { Observable } from "rxjs";
import { AjaxResponse } from "rxjs/ajax";
/*import {
  TOKEN_HEADER_NAME,
  LOCAL_STORAGE_TOKEN_NAME
} from "../constants/AppConstants";*/
import { RulesData } from "../types/apiTypes";
import {
  API_ENDPOINT,
  API_SETTINGS /*, BASE_HEADERS*/
} from "./ApiBaseSettings";
import { trackedAjaxGetJson } from "./AppInsigthsTracker";

export type RulesApiParams = {
  geo: string;
  time: string;
  realTime: boolean;
};

export const getRules = ({
  geo,
  time,
  realTime
}: RulesApiParams): Observable<AjaxResponse<RulesData>> => {
  return trackedAjaxGetJson<RulesData>({
    ...API_SETTINGS,
    url: `${API_ENDPOINT}rules/${geo}/${time}?realtime=${realTime}`
  });
};
