import _ from "lodash";
import React from "react";
import { Dimensions, ScaledSize } from "react-native";
import useMountEffect from "./useMountEffect";

type LayoutState = {
  width: number;
  height: number;
};
type LayoutObject = { window: ScaledSize; screen: ScaledSize };
const PIXEL_REFRESH_RANGE = 50;

const useLayout = (): LayoutState => {
  const [layoutState, setLayoutState] = React.useState<LayoutState>({
    width: Dimensions.get("window").width,
    height: Dimensions.get("window").height
  });

  const handler = ({ window }: LayoutObject) => {
    const debouncedFn = _.debounce(() => {
      setLayoutState(prevState => {
        if (
          Math.abs(prevState.width - window.width) >= PIXEL_REFRESH_RANGE ||
          Math.abs(prevState.height - window.height) >= PIXEL_REFRESH_RANGE
        ) {
          return {
            width: window.width,
            height: window.height
          };
        } else {
          return prevState;
        }
      });
    }, 200);
    debouncedFn();
  };

  useMountEffect(() => {
    Dimensions.addEventListener("change", handler);
    return () => {
      Dimensions.removeEventListener("change", handler);
    };
  });
  return layoutState;
};

export default useLayout;
