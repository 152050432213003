import { BehaviorSubject } from "rxjs";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import SettingsService from "../services/SettingsService";
import { SettingsState, User, VPaySettingsStore } from "../types/storesTypes";
import { Settings } from "../types/apiTypes";
import {
  LANGUAGES,
  RiskKpiEnum,
  Theme,
  LOCAL_STORAGE_LANGUAGE_NAME,
  LOCAL_STORAGE_THEME_NAME
} from "../constants/AppConstants";

const defaultState: SettingsState = {
  languages: LANGUAGES,
  selectedLanguage: "en",
  realTime: false,
  filters: undefined,
  selectedGeoFilter: "",
  selectedTimeFilter: "",
  selectedKpiFilter: RiskKpiEnum.COUNT,
  hasError: false,
  loadGauge: 0,
  darkMode: true,
  errorMessage: undefined,
  appInsightsObj: undefined,
  user: {
    firstName: "",
    lastName: "",
    admin: false,
    preferences: {},
    email: "",
    sub: "",
    // eslint-disable-next-line @typescript-eslint/camelcase
    user_id: ""
  }
};

const subject = new BehaviorSubject<SettingsState>(defaultState);

let state = defaultState;

const settingsStore: VPaySettingsStore = {
  initSettings: (userResponse: User) => {
    const user: User = Object.assign(
      {},
      ...Object.keys(state.user).map(key => ({
        [key]: userResponse[key as keyof User]
      }))
    );
    const storageLanguage = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_NAME);
    const storageTheme = localStorage.getItem(LOCAL_STORAGE_THEME_NAME);
    // priority for preferences goes to local storage preference
    const selectedLanguage = storageLanguage
      ? storageLanguage
      : user.preferences.language;
    const theme = storageTheme ? storageTheme : user.preferences.theme;
    state = {
      ...state,
      user,
      selectedLanguage: selectedLanguage || "en",
      darkMode: theme ? theme === Theme.DARK : true
    };
    SettingsService.callRiskFilters().subscribe(settings => {
      if (settings) {
        state = {
          ...state,
          filters: settings as Settings,
          selectedGeoFilter:
            (settings as Settings).geo.find(filter => filter.selected)?.id ||
            "",
          selectedTimeFilter:
            (settings as Settings).time.find(filter => filter.selected)?.id ||
            ""
        };
        subject.next(state);
      }
    });
  },
  subscribe: (setState: React.Dispatch<SettingsState>) =>
    subject.subscribe(setState),
  changeLanguage: (languageId: string) => {
    SettingsService.callSetPreferences({ language: languageId }).subscribe(
      _ => {
        state = { ...state, selectedLanguage: languageId };
        localStorage.setItem(LOCAL_STORAGE_LANGUAGE_NAME, languageId);
        subject.next(state);
      }
    );
  },
  toggleTheme: () => {
    const newTheme = state.darkMode ? Theme.LIGHT : Theme.DARK;
    SettingsService.callSetPreferences({ theme: newTheme }).subscribe(_ => {
      state = { ...state, darkMode: newTheme === Theme.DARK };
      localStorage.setItem(LOCAL_STORAGE_THEME_NAME, newTheme);
      subject.next(state);
    });
  },
  toggleRealTime: () => {
    state = { ...state, realTime: !state.realTime };
    subject.next(state);
  },
  changeGeoFilter: (filterId: string) => {
    state = { ...state, selectedGeoFilter: filterId };
    subject.next(state);
  },
  changeTimeFilter: (filterId: string) => {
    state = { ...state, selectedTimeFilter: filterId };
    subject.next(state);
  },
  changeUnitFilter: (filterId: RiskKpiEnum) => {
    state = { ...state, selectedKpiFilter: filterId };
    subject.next(state);
  },
  setLoadingGauge: (value: number) => {
    state = { ...state, loadGauge: value };
    subject.next(state);
  },
  setAppInsights: (appInsightsObj: ApplicationInsights) => {
    state = { ...state, appInsightsObj: appInsightsObj };
    subject.next(state);
  },
  setError: (
    errorStatus: boolean,
    errorMessage?: string,
    msgDetails?: string
  ) => {
    state = { ...state, hasError: errorStatus, errorMessage, msgDetails };
    subject.next(state);
  },
  getGeoFilter: (filterId: string) => {
    return state.filters?.geo.find(filter => filter.id === filterId || "");
  },
  getTimeFilter: (filterId: string) => {
    return state.filters?.time.find(filter => filter.id === filterId || "");
  },
  getSelectedLanguage: () => {
    return `${state.selectedLanguage}`;
  },
  getActiveTheme: () => {
    return `${state.user.preferences.theme}`;
  },
  getAppInsights: () => {
    return state.appInsightsObj;
  },
  getUser: () => {
    return state.user;
  },
  default: defaultState
};

export default settingsStore;
