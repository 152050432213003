import React from "react";
import { StyleSheet, View, Platform } from "react-native";
import { ThemeContext } from "../context/ThemeContext";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { LATERAL_DESKTOP_VIEW_PADDING_WIDTH } from "../constants/StyleConstants";
import useLayout from "../hooks/useLayout";
import useScale from "../hooks/useScale";

const VpProgressBar = (props: { progress: number }) => {
  const { progress } = props;
  const { colors } = React.useContext(ThemeContext);
  const { top } = useSafeAreaInsets();
  const { width } = useLayout();
  const { moderateScale } = useScale();

  const lateralDesktopBandsWidth =
    Platform.OS === "web" && width >= 1280
      ? LATERAL_DESKTOP_VIEW_PADDING_WIDTH * 2
      : 0;
  const progressBarMaxLength = width - lateralDesktopBandsWidth;
  const VpProgressBarStyle = StyleSheet.create({
    container: {
      height: moderateScale(4, 0.2),
      backgroundColor: colors.oddValue,
      marginTop: top
    },
    filler: {
      width: (progressBarMaxLength * progress) / 100,
      height: moderateScale(4, 0.2),
      backgroundColor: colors.tintColor
    }
  });

  return (
    <View style={VpProgressBarStyle.container}>
      <View style={VpProgressBarStyle.filler} />
    </View>
  );
};

export default VpProgressBar;
