import { catchError, map } from "rxjs/operators";
import { of } from "rxjs";
import {
  getRisk,
  getRiskDetail,
  RiskApiParams,
  RiskDetailApiParams
} from "../apis/RiskApi";
import handleError from "../utils/handleError";

class RiskService {
  callRisk = (params: RiskApiParams) => {
    return getRisk(params).pipe(
      map(res => {
        return {
          ...res.response
        };
      }),
      catchError(err => {
        handleError(err);
        return of(err);
      })
    );
  };

  callRiskDetail = (params: RiskDetailApiParams) => {
    return getRiskDetail(params).pipe(
      map(res => {
        return {
          ...res.response
        };
      }),
      catchError(err => {
        handleError(err);
        return of(err);
      })
    );
  };
}

export default new RiskService();
