import React from "react";
import { ButtonGroup } from "react-native-elements";
import { Filter } from "../types/apiTypes";
import { StyleSheet, StyleProp, ViewStyle } from "react-native";
import { ThemeContext } from "../context/ThemeContext";
import useScale, { Scales } from "../hooks/useScale";
import { VPayColors } from "../types/appTypes";
import { ROBOTO } from "../constants/StyleConstants";

const filterComponentStyle = (colors: VPayColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    container: {
      maxHeight: moderateScale(20, 0.2),
      backgroundColor: colors.filterBackground,
      borderRadius: 0,
      marginHorizontal: 0
    },
    text: {
      fontFamily: ROBOTO,
      color: colors.defaultText,
      fontSize: moderateScale(10, 0.2)
    },
    selectedContainer: {
      backgroundColor: colors.selectedButtonColor
    },
    selectedText: {
      fontWeight: "bold",
      color: colors.selectedFilterText
    }
  });

type FilterComponentProps = {
  filters: Filter[];
  selectedFilter: string;
  changeFilter: (filterId: string) => void;
  language: string;
  containerStyle?: StyleProp<ViewStyle>;
};

export default ({
  filters,
  selectedFilter,
  changeFilter,
  language,
  containerStyle
}: FilterComponentProps) => {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  return (
    <ButtonGroup
      onPress={index => {
        changeFilter(filters[index].id);
      }}
      selectedIndex={filters.findIndex(
        (filter: Filter) => filter.id === selectedFilter
      )}
      buttons={filters.map((fil: Filter) => fil.label[language])}
      containerStyle={[
        filterComponentStyle(colors, scaleFunctions).container,
        { borderWidth: 0 },
        containerStyle
      ]}
      innerBorderStyle={{ color: colors.screenBackgroundColor }}
      textStyle={filterComponentStyle(colors, scaleFunctions).text}
      selectedTextStyle={
        filterComponentStyle(colors, scaleFunctions).selectedText
      }
      selectedButtonStyle={
        filterComponentStyle(colors, scaleFunctions).selectedContainer
      }
    />
  );
};
