import { Observable } from "rxjs";
/*import {
  TOKEN_HEADER_NAME,
  LOCAL_STORAGE_TOKEN_NAME
} from "../constants/AppConstants";*/
import { BankData } from "../types/apiTypes";
import {
  API_ENDPOINT,
  API_SETTINGS /*, BASE_HEADERS*/
} from "./ApiBaseSettings";
import { RiskApiParams } from "./RiskApi";
import { trackedAjaxGetJson } from "./AppInsigthsTracker";
import { AjaxResponse } from "rxjs/ajax";

export const getBanks = ({
  geo,
  time,
  realTime
}: RiskApiParams): Observable<AjaxResponse<BankData>> => {
  return trackedAjaxGetJson<BankData>({
    ...API_SETTINGS,
    url: `${API_ENDPOINT}banks/${geo}/${time}?realtime=${realTime}`
  });
};
