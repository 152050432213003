import { SettingsState } from "../types/storesTypes";
import settingsStore from "../stores/settingsStore";
import useGlobalState from "./useGlobalState";

const useSettingsState = (
  defaultState?: SettingsState
): SettingsState | undefined => {
  const settingsState = useGlobalState(settingsStore, defaultState);
  return settingsState;
};

export default useSettingsState;
