import { catchError, map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import {
  getSettings,
  getInfo,
  setPreferences,
  getUser
} from "../apis/SettingsApi";
import handleError from "../utils/handleError";
import { Preferences, User } from "../types/storesTypes";
import { AjaxError, AjaxResponse } from "rxjs/ajax";
import { Info, Settings } from "../types/apiTypes";

class SettingsService {
  callRiskFilters(): Observable<Settings | null | AjaxError> {
    return getSettings().pipe(
      map(res => {
        return {
          ...res.response
        };
      }),
      catchError(err => {
        handleError(err);
        return of(null);
      })
    );
  }

  callInfo(): Observable<Info | AjaxError> {
    return getInfo().pipe(
      map(res => {
        return { ...res.response };
      }),
      catchError(err => {
        handleError(err);
        return of(err);
      })
    );
  }

  callSetPreferences(
    preferences: Preferences
  ): Observable<AjaxResponse<void> | AjaxError> {
    return setPreferences(preferences).pipe(
      catchError(err => {
        handleError(err);
        return of(err);
      })
    );
  }

  getUser(): Observable<User | AjaxError> {
    return getUser().pipe(
      map(res => {
        return {
          ...res.response
        };
      }),
      catchError(err => {
        return of(err as AjaxError);
      })
    );
  }
}

export default new SettingsService();
