import React from "react";
import FilterComponent from "./FilterComponent";
import { SettingsState } from "../types/storesTypes";
import { View } from "react-native";
import settingsStore from "../stores/settingsStore";

type FiltersProps = {
  settingsState: SettingsState;
};

const Filters = ({ settingsState }: FiltersProps) => {
  const geoFilters = settingsState.filters?.geo || [];
  const timeFilters = settingsState.filters?.time || [];

  return (
    <View>
      <FilterComponent
        filters={geoFilters}
        selectedFilter={settingsState.selectedGeoFilter || ""}
        changeFilter={settingsStore.changeGeoFilter}
        language={settingsState.selectedLanguage}
      />
      <FilterComponent
        filters={timeFilters}
        selectedFilter={settingsState.selectedTimeFilter || ""}
        changeFilter={settingsStore.changeTimeFilter}
        language={settingsState.selectedLanguage}
      />
    </View>
  );
};

export default Filters;
