import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { BankApiParams } from "../apis/RiskApi";
import handleError from "../utils/handleError";
import { getBanks } from "../apis/BankApi";

class BankService {
  callBanks = (params: BankApiParams) => {
    return getBanks(params).pipe(
      map(res => {
        return {
          ...res.response
        };
      }),
      catchError(err => {
        handleError(err);
        return of(err);
      })
    );
  };
}

export default new BankService();
