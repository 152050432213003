import { VPayColors } from "./../types/appTypes";
const lightTintColor = "#71bbe6";
const darkTintColor = "#F7A26B";

export default {
  light: {
    tintColor: lightTintColor,
    screenBackgroundColor: "#F0F5F9",
    defaultText: "#000",
    whiteText: "#fff",
    selectedButtonColor: lightTintColor,
    tabIconDefault: "#ccc",
    tabIconSelected: lightTintColor,
    tabBar: "#fefefe",
    timeBarBackground: lightTintColor,
    timeBarText: "#000",
    dividerColor: "rgba(0, 0, 0, 0.12)",
    filterBackground: "#bfcce2",
    evenHeader: "#dce6f5",
    oddHeader: "#e7f0f7",
    evenValue: "#e7eef6",
    oddValue: "#F0F5F9",
    tableHeader: "#d7e5f5",
    selectedFilterText: "#000",
    loadingScreenBackground: "rgba(240, 245, 249, 0.8)",
    tableRowBorder: "#bfcce2",
    bodyHeaderTitleText: "#383737",
    alertBar: "#e13d66",
    toCheckBar: "#ccdaf7",
    colCellSubtitle: "#2f3442",
    graphLabels: "#383737",
    filledDot: "#383737",
    emptyDot: "#fff",
    nextIconColor: "#000",
    overviewGraphBarBackground: "#d7e5f5",
    veryHighBar: "#FE3356",
    highBar: "#F7A26B",
    veryHighText: "#FF3E5F",
    highText: "#FFB586"
  } as VPayColors,
  dark: {
    tintColor: darkTintColor,
    screenBackgroundColor: "rgb(5,14,36)",
    defaultText: "#fff",
    whiteText: "#fff",
    selectedButtonColor: darkTintColor,
    tabIconDefault: "#ccc",
    tabIconSelected: darkTintColor,
    tabBar: "#fefefe",
    timeBarBackground: darkTintColor,
    timeBarText: "#000",
    dividerColor: "#fff",
    filterBackground: "#3b4355",
    evenHeader: "#343C4E",
    oddHeader: "#3E4557",
    evenValue: "#272D3A",
    oddValue: "#323744",
    tableHeader: "#272D3A",
    selectedFilterText: "#000",
    loadingScreenBackground: "rgba(5,14,36, 0.8)",
    tableRowBorder: "#636a77",
    bodyHeaderTitleText: "#b3b9c8",
    alertBar: "#f14734",
    toCheckBar: "#5aa1dd",
    colCellSubtitle: "#C0C6D5",
    graphLabels: "#CACDCF",
    filledDot: "#000",
    emptyDot: "#fff",
    nextIconColor: "#C0C6D5",
    overviewGraphBarBackground: "rgba(0,0,0,0.7)",
    veryHighBar: "#FE3356",
    highBar: "#F7A26B",
    veryHighText: "#FF3E5F",
    highText: "#FFB586"
  } as VPayColors
};
