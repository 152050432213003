import React, { useState, useEffect } from "react";
import { ThemeContext } from "../context/ThemeContext";
import Colors from "../constants/Colors";
import { Theme } from "../constants/AppConstants";
import settingsStore from "../stores/settingsStore";

const ThemeProvider = (props: any) => {
  const [{ darkMode }, setSettingsState] = useState(settingsStore.default);

  useEffect(() => {
    const settingsStoreSubscription = settingsStore.subscribe(setSettingsState);
    return () => {
      settingsStoreSubscription.unsubscribe();
    };
  }, []);

  const toggle = () => {
    settingsStore.toggleTheme();
  };

  return (
    <ThemeContext.Provider
      value={{
        darkMode,
        toggle,
        colors: Colors[darkMode ? Theme.DARK : Theme.LIGHT]
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
