import React, { useContext } from "react";
import { View } from "react-native";
import { SettingsState } from "../types/storesTypes";
import RiskDetailGraph from "./RiskDetailGraph";
import RiskKpiSwitch from "./RiskKpiSwitch";
import { RiskDetailData } from "../types/apiTypes";
import { RankEnum } from "../constants/AppConstants";
import RiskDetailTable from "./RiskDetailTable";
import GraphLegend from "./GraphLegend";
import NoDataView from "./NoDataView";
import { ThemeContext } from "../context/ThemeContext";
import {
  GRAPH_BAR_LEGEND_LABELS,
  NO_DETAIL_TO_SHOW
} from "../constants/Dictionary";
import useScale from "../hooks/useScale";
import { GRAPH_CONTAINER_HEIGHT } from "../constants/StyleConstants";

type RiskDetailBodyProps = {
  riskDetailApi: RiskDetailData;
  settingsState: SettingsState;
  riskRank: RankEnum;
};

const RiskDetailBody = ({
  settingsState,
  riskDetailApi,
  riskRank
}: RiskDetailBodyProps) => {
  const { colors } = useContext(ThemeContext);
  const scales = useScale();
  const barColors = [colors.toCheckBar, colors.alertBar];

  const renderGraph = () => {
    if (riskDetailApi.table.items.length === 0) {
      return <NoDataView settingsState={settingsState} />;
    } else if (
      riskDetailApi.table.items.every(
        item => !item.data.countToCheck && !item.data.countAlert
      )
    ) {
      return (
        <NoDataView
          settingsState={settingsState}
          text={NO_DETAIL_TO_SHOW[settingsState.selectedLanguage]}
        />
      );
    } else {
      return (
        <View style={{ flex: 1 }}>
          <RiskDetailGraph
            settingsState={settingsState}
            riskDetailApi={riskDetailApi}
          />
          <GraphLegend
            language={settingsState.selectedLanguage}
            additionalStyle={{ paddingBottom: 10 }}
            graphColors={barColors}
            legendLabels={GRAPH_BAR_LEGEND_LABELS}
          />
        </View>
      );
    }
  };

  return (
    <View style={{ flex: 1 }}>
      <View
        style={{
          flex: 1,
          maxHeight: scales.moderateScale(...GRAPH_CONTAINER_HEIGHT)
        }}
      >
        <RiskKpiSwitch settingsState={settingsState} />
        {renderGraph()}
      </View>
      <View style={{ flex: 1 }}>
        <RiskDetailTable
          settingsState={settingsState}
          riskDetailApi={riskDetailApi}
          riskR={riskRank}
        />
      </View>
    </View>
  );
};

export default RiskDetailBody;
