import React from "react";
import TabNavigator from "./MainTabNavigator";
import { createDrawerNavigator } from "@react-navigation/drawer";
import DrawerContent from "../components/DrawerContent";
import { generalStackScreen } from "./GeneralStack";
import { ADVANCED, HOME } from "../constants/AppConstants";
import { Platform } from "react-native";
import useLayout from "../hooks/useLayout";

const Drawer = createDrawerNavigator();

export const DrawerScreen = () => {
  const { width } = useLayout();
  return (
    <Drawer.Navigator
      drawerContent={props => <DrawerContent {...props} />}
      initialRouteName={HOME}
      drawerStyle={Platform.OS === "web" && width >= 1280 && { left: -620 }}
    >
      <Drawer.Screen name={HOME} component={TabNavigator} />
      <Drawer.Screen
        name={ADVANCED}
        component={generalStackScreen}
        options={{ gestureEnabled: false, unmountOnBlur: true }}
      />
    </Drawer.Navigator>
  );
};
