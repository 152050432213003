import { TextStyle } from "react-native";
import { ScaleFunctionArgs } from "./../hooks/useScale";

/* FONT NAMES */
export const ROBOTO = "roboto";
export const ROBOTO_BOLD = "roboto-bold";
export const ROBOTO_MEDIUM = "roboto-medium";
export const ROBOTO_ITALIC = "roboto-italic";

export const NORMAL_FONT: TextStyle = { fontFamily: ROBOTO };
export const BOLD_FONT: TextStyle = {
  fontFamily: ROBOTO_BOLD
};
export const MEDIUM_FONT: TextStyle = {
  fontFamily: ROBOTO_MEDIUM
};

export const GRAPH_CONTAINER_HEIGHT: ScaleFunctionArgs = [230, 0.2];
export const TABLE_HEADER_HEIGHT: ScaleFunctionArgs = [27, 0.2];
export const OVERVIEW_TABLE_ROWS_HEIGHT: ScaleFunctionArgs = [45, 0.2];
export const DETAIL_TABLE_ROWS_HEIGHT: ScaleFunctionArgs = [37, 0.2];
export const OVERVIEW_TABLE_FIRST_COLUMN_TEXT: ScaleFunctionArgs = [12.5, 0.2];
export const DETAIL_TABLE_FIRST_COLUMN_TEXT_SIZE: ScaleFunctionArgs = [12, 0.2];
export const LATERAL_DESKTOP_VIEW_PADDING_WIDTH = 300;
export const SPEEDOMETER_WRAPPER_WIDTH_ARGS: ScaleFunctionArgs = [300];
export const NEEDLE_WIDTH_ARGS: ScaleFunctionArgs = [85];
export const NEEDLE_HEIGHT_ARGS: ScaleFunctionArgs = [7];
export const SPEEDOMETER_CENTER_BORDER_RADIUS_ARGS: ScaleFunctionArgs = [140];
export const OVERVIEW_WIDTH_ARGS: ScaleFunctionArgs = [90, 0.3];
export const OVERVIEW_WRAPPER_WIDTH_ARGS: ScaleFunctionArgs = [250, 0.3];
export const OVERVIEW_CENTER_BORDER_RADIUS_ARGS: ScaleFunctionArgs = [140, 0.3];

export const FONT_SIZE_BASE_LITE: ScaleFunctionArgs = [11, 0.2];
export const FONT_SIZE_BASE: ScaleFunctionArgs = [12, 0.2];
