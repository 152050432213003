import { RankEnum } from "../constants/AppConstants";

export type AppSettings = {
  languages: Array<Language>;
};

export type Language = {
  label: Label;
  id: string;
};

export type Settings = {
  home: string;
  geo: Array<Filter>;
  time: Array<TimeFilter>;
};

export type Label = { [key: string]: string };
export type LabelLong = { [key: string]: string };

export interface Filter {
  id: string;
  selected: boolean;
  label: Label;
  labelLong: LabelLong;
}

export interface TimeFilter extends Filter {
  gregFrom: string;
  gregTo: string;
}

export type Info = {
  version: string;
  launchDate: string;
  cutDate: string;
};

export type TimeInfo = {
  launchDate: string;
  gregFrom: string;
  gregTo: string;
};

export interface Response {
  time: TimeInfo;
}

export interface RiskOverviewData extends Response {
  graphRisk: number;
  table: RiskTableData;
}

export type RiskTableData = {
  head: RiskHeadData;
  items: RiskItemsData[];
};

export interface RiskHeadData {
  count: number;
  amount: number;
  avgAmount: number;
  url?: string;
}

export interface RiskItemsData extends RiskHeadData {
  weightCount: number;
  weightAmount: number;
  id: RankEnum;
}

export interface FilteredItem {
  avgAmount: string | number;
  weight: number;
  id: RankEnum;
  countOrAmount: string | number;
}

export interface RiskDetailData extends Response {
  table: RiskDetailTable;
}

export type RiskDetailTable = {
  head: RiskDetailTableHead;
  items: RiskDetailTableHead[];
};

export type RiskDetailTableHead = {
  data: PaymentData;
  geo: string;
  geoLabel: Label;
  respo: string;
  url: string;
};

export enum PaymentAmountGraphEnum {
  AMOUNT_ALERT = "amountAlert",
  AMOUNT_TO_CHECK = "amountToCheck"
}

export enum PaymentCountGraphEnum {
  COUNT_ALERT = "countAlert",
  COUNT_TO_CHECK = "countToCheck"
}

export type PaymentAmountGraphData = {
  [key in PaymentAmountGraphEnum]: number;
};

export type PaymentCountGraphData = {
  [key in PaymentCountGraphEnum]: number;
};

export enum PaymentAmountEnum {
  AMOUNT = "amount"
}

export enum PaymentCountEnum {
  COUNT = "count"
}

export type PaymentAmountData = {
  [key in PaymentAmountEnum | PaymentAmountGraphEnum]: number;
};

export type PaymentCountData = {
  [key in PaymentCountEnum | PaymentCountGraphEnum]: number;
};

export type PaymentData = PaymentAmountData & PaymentCountData;

export interface RulesData extends Response {
  table: RulesTable;
}

export interface RulesTable {
  head: RulesHead;
  items: RulesItem[];
}

export interface RulesHead {
  url: string;
  veryHighCount: number;
  highCount: number;
}

export interface RulesItem {
  ruleId: string;
  ruleLabel: Label;
  sort: number | null;
  veryHighHits: number;
  highHits: number;
}

export interface BankData extends Response {
  table: BankTable;
}

export interface BankTable {
  head: BankHead;
  items: BankItem[];
}

export interface BankHead {
  veryHighCount: number;
  highCount: number;
  url: string;
  count: number;
}

export interface BankItem {
  veryHighHits: number;
  highHits: number;
  bankId: string;
  bankLabel: Label;
  count: number;
}
