import React, { useState, useContext } from "react";
import {
  Platform,
  StyleSheet,
  StatusBar,
  View,
  Dimensions
} from "react-native";
import AppNavigator from "../navigation/AppNavigator";
import { IS_LOCAL, LOCAL_STORAGE_LOGGED_NAME } from "../constants/AppConstants";
import settingsStore from "../stores/settingsStore";
import useMountEffect from "../hooks/useMountEffect";
import { ThemeContext } from "../context/ThemeContext";
import ErrorScreen from "../screens/ErrorScreen";
import VpProgressBar from "./VpProgressBar";
import { LATERAL_DESKTOP_VIEW_PADDING_WIDTH } from "../constants/StyleConstants";
import LoadingView from "./LoadingView";
import useLayout from "../hooks/useLayout";
import { VPayColors } from "../types/appTypes";
import { ScrollView } from "react-native-gesture-handler";
import appTrackingService from "../services/appTracking.service";
import { getUserLogged, removeTokenFromUrl } from "../utils/loginUtils";
import { API_ENDPOINT } from "../apis/ApiBaseSettings";
import { ajax } from "rxjs/ajax";
import handleError, { isError } from "../utils/handleError";
import SettingsService from "../services/SettingsService";
import { User } from "../types/storesTypes";

const styles = (colors: VPayColors, width: number) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingLeft:
        Platform.OS === "web" && width >= 1280
          ? LATERAL_DESKTOP_VIEW_PADDING_WIDTH
          : 0,
      paddingRight:
        Platform.OS === "web" && width >= 1280
          ? LATERAL_DESKTOP_VIEW_PADDING_WIDTH
          : 0,
      backgroundColor: colors.screenBackgroundColor
    }
  });

const Main = () => {
  const [settingsState, setSettingsState] = useState(settingsStore.default);
  const { colors } = useContext(ThemeContext);
  const { width, height } = useLayout();
  const [logged, setLogged] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<User | null>(null);

  useMountEffect(() => {
    const settingsStateSubscription = settingsStore.subscribe(setSettingsState);
    const [userLogged] = getUserLogged();
    if (userLogged) {
      removeTokenFromUrl();
      localStorage.setItem(LOCAL_STORAGE_LOGGED_NAME, String(userLogged));
      setLogged(userLogged);
    } else {
      ajax.get<string>(`${API_ENDPOINT}login/nam`).subscribe(ret => {
        if (IS_LOCAL() || ret.response === null) {
          const href = `/login.html`;
          window.location.href = href;
        } else {
          window.location.replace(ret.response);
        }
      });
    }
    return () => {
      settingsStateSubscription.unsubscribe();
    };
  });

  React.useEffect(() => {
    if (logged) {
      SettingsService.getUser().subscribe(res => {
        if (isError(res)) {
          handleError(res);
        } else {
          settingsStore.initSettings(res);
          setUser(res);
          if (settingsStore.getAppInsights() === undefined) {
            const appInsights = appTrackingService.setAppTracking(res);
            appInsights && settingsStore.setAppInsights(appInsights);
          }
        }
      });
    }
  }, [logged]);

  if (settingsState.hasError) {
    return (
      <ErrorScreen
        errorMessage={settingsState.errorMessage || ""}
        msgDetail={settingsState.msgDetails || ""}
      />
    );
  }

  if (logged && user) {
    if (Platform.OS === "web" && Dimensions.get("screen").width > 450) {
      return (
        <ScrollView style={{ height }} contentContainerStyle={{ flex: 1 }}>
          <View
            style={[
              styles(colors, width).container,
              { minHeight: width > 450 ? 660 : 0 }
            ]}
          >
            <StatusBar animated barStyle="dark-content" hidden={true} />
            <VpProgressBar progress={settingsState.loadGauge} />
            <AppNavigator />
          </View>
        </ScrollView>
      );
    }
    return (
      <View style={styles(colors, width).container}>
        <StatusBar animated barStyle="dark-content" hidden={true} />
        <VpProgressBar progress={settingsState.loadGauge} />
        <AppNavigator />
      </View>
    );
  } else {
    return (
      <View style={styles(colors, width).container}>
        <LoadingView />
      </View>
    );
  }
};

export default Main;
