import React, { useState, useEffect } from "react";
import { View, StyleSheet, Text, Switch } from "react-native";
import {
  DrawerContentScrollView,
  DrawerItem,
  DrawerContentComponentProps
} from "@react-navigation/drawer";
import { Avatar, Icon, Divider, ListItem } from "react-native-elements";

import LanguageItem from "./LanguageItem";
import { SettingsState } from "../types/storesTypes";
import settingsStore from "../stores/settingsStore";
import { ADVANCED } from "../constants/AppConstants";
import {
  LANGUAGE,
  PREFERENCES,
  DARK_THEME,
  ADV_PREFERENCES
} from "../constants/Dictionary";
import { Language } from "../types/apiTypes";
import { ThemeContext } from "../context/ThemeContext";
import AppText from "./text/AppText";
import { VPayColors } from "../types/appTypes";
import { ROBOTO_MEDIUM } from "../constants/StyleConstants";

const drawerContentStyles = (colors: VPayColors) =>
  StyleSheet.create({
    avatar: {
      paddingTop: 5,
      alignSelf: "center"
    },
    preference: {
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "space-between",
      paddingVertical: 12,
      paddingHorizontal: 16
    },
    langPreference: {
      flexDirection: "column",
      paddingVertical: 12,
      paddingHorizontal: 16
    },
    drawerSectionTitle: { padding: 10 },
    drawerText: {
      fontFamily: ROBOTO_MEDIUM,
      color: colors.defaultText,
      fontWeight: "500",
      fontSize: 14,
      paddingLeft: 5
    },
    refreshButtonContainer: {
      backgroundColor: colors.tintColor,
      margin: 10
    },
    refreshButtonTitle: {
      color: colors.whiteText
    },
    listItemContainerStyle: { backgroundColor: colors.screenBackgroundColor },
    drawerContentStyle: { backgroundColor: colors.screenBackgroundColor }
  });

type DrawerHeaderProps = {
  settingsState: SettingsState;
};

const DrawerHeader = ({ settingsState }: DrawerHeaderProps) => {
  const { colors } = React.useContext(ThemeContext);
  return (
    <View style={{ marginBottom: 5 }}>
      <View style={drawerContentStyles(colors).avatar}>
        <Avatar
          size={110}
          rounded
          source={require("../../assets/images/Luxottica.png")}
        />
      </View>
      <ListItem
        key="1"
        containerStyle={drawerContentStyles(colors).listItemContainerStyle}
        title={`${settingsState.user.firstName} ${settingsState.user.lastName}`}
        leftIcon={{ name: "face", color: colors.defaultText }}
        titleStyle={[drawerContentStyles(colors).drawerText]}
      />
      <ListItem
        key="2"
        containerStyle={drawerContentStyles(colors).listItemContainerStyle}
        title={settingsState.user.email}
        leftIcon={{ name: "email", color: colors.defaultText }}
        titleStyle={[drawerContentStyles(colors).drawerText]}
      />
    </View>
  );
};

type DrawerItemsProps = {
  settingsState: SettingsState;
};

const DrawerItems = (props: DrawerContentComponentProps & DrawerItemsProps) => {
  const { selectedLanguage } = props.settingsState;
  const { colors } = React.useContext(ThemeContext);
  return (
    <View>
      <DrawerItem
        labelStyle={{ color: colors.defaultText }}
        icon={() => (
          <Icon
            name="settings"
            type="material"
            iconStyle={{ color: colors.defaultText }}
          />
        )}
        label={ADV_PREFERENCES[selectedLanguage]}
        onPress={() => props.navigation.navigate(ADVANCED)}
      />
    </View>
  );
};

type DrawerPreferencesProps = {
  settingsState: SettingsState;
};

const DrawerPreferences = ({ settingsState }: DrawerPreferencesProps) => {
  const [isLangVisible, setIsLangVisible] = useState(false);
  const { selectedLanguage, languages } = settingsState;
  const { darkMode, colors, toggle } = React.useContext(ThemeContext);

  return (
    <View>
      {/* title section */}
      <Text
        style={[
          drawerContentStyles(colors).drawerSectionTitle,
          drawerContentStyles(colors).drawerText
        ]}
      >
        {PREFERENCES[selectedLanguage]}
      </Text>

      {/* language section */}
      <View>
        <ListItem
          title={LANGUAGE[selectedLanguage]}
          containerStyle={drawerContentStyles(colors).listItemContainerStyle}
          leftIcon={{
            name: "language",
            type: "material",
            color: colors.defaultText
          }}
          titleStyle={[drawerContentStyles(colors).drawerText]}
          onPress={() => setIsLangVisible(!isLangVisible)}
        />
        <View
          style={[
            drawerContentStyles(colors).langPreference,
            !isLangVisible && { display: "none" }
          ]}
        >
          {languages.map((item: Language) => (
            <LanguageItem
              key={item.id}
              id={item.id}
              label={item.label[selectedLanguage]}
              isSelected={selectedLanguage === item.id}
            />
          ))}
        </View>
      </View>

      {/* theme section */}
      <View style={drawerContentStyles(colors).preference}>
        <AppText style={drawerContentStyles(colors).drawerText}>
          {DARK_THEME[settingsState.selectedLanguage]}
        </AppText>
        <Switch value={darkMode} onValueChange={toggle} />
      </View>
    </View>
  );
};

export default function DrawerContent(props: DrawerContentComponentProps) {
  const [settingsState, setSettingsState] = useState<SettingsState>(
    settingsStore.default
  );

  useEffect(() => {
    const settingsStoreSubscription = settingsStore.subscribe(setSettingsState);

    return () => {
      settingsStoreSubscription.unsubscribe();
    };
  }, []);

  const { colors } = React.useContext(ThemeContext);

  return (
    <DrawerContentScrollView
      style={drawerContentStyles(colors).drawerContentStyle}
    >
      <DrawerHeader settingsState={settingsState} />
      <Divider style={{ backgroundColor: colors.dividerColor }} />
      <DrawerItems {...props} settingsState={settingsState} />
      <Divider style={{ backgroundColor: colors.dividerColor }} />
      <DrawerPreferences settingsState={settingsState} />
    </DrawerContentScrollView>
  );
}
