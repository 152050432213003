import React from "react";
import { Platform, StyleSheet, View } from "react-native";
import {
  Table,
  TableWrapper,
  Row,
  Cell
} from "@deb-95/react-native-table-component";
import { ThemeContext } from "../../context/ThemeContext";
import { RulesItem } from "../../types/apiTypes";
import { formatPercentages } from "../../utils/num_formatting";
import {
  RANK_TRANS,
  RULES_TABLE_ID,
  RULES_TABLE_NAME
} from "../../constants/Dictionary";
import {
  NO_DATA_NUMBER,
  TABLE_LINE_HEIGHT_ARGS
} from "../../constants/AppConstants";
import useScale, { Scales } from "../../hooks/useScale";
import { VPayColors } from "../../types/appTypes";
import TableDescendingSortHeader from "../TableDescendingSortHeader";
import { ROBOTO, FONT_SIZE_BASE } from "../../constants/StyleConstants";

const headerFlexArr = [0.4, 2.75, 1.1, 0.75];
const flexArr = [0.4, 3.1, 0.9, 0.7];

const NO_DATA_ROW = [
  [NO_DATA_NUMBER, NO_DATA_NUMBER, NO_DATA_NUMBER, NO_DATA_NUMBER]
];

const styles = (colors: VPayColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    cellLeft: {
      textAlign: "left",
      paddingLeft: moderateScale(8, 0.2)
    },
    cellRight: {
      textAlign: "right",
      paddingRight: moderateScale(8, 0.2)
    },
    textStyles: {
      color: colors.defaultText,
      fontSize: moderateScale(...FONT_SIZE_BASE),
      fontFamily: ROBOTO
    },
    borders: { borderBottomWidth: 1, borderBottomColor: colors.tableRowBorder },
    veryHighHits: {
      color: colors.veryHighText,
      fontSize: moderateScale(...FONT_SIZE_BASE),
      textAlign: "right",
      paddingRight: moderateScale(5, 0.2)
    },
    highHits: {
      color: colors.highText,
      fontSize: moderateScale(...FONT_SIZE_BASE),
      textAlign: "right",
      paddingRight: moderateScale(5, 0.2)
    }
  });

const getTableHead = (
  selectedLanguage: string,
  colors: VPayColors,
  scaleFunctions: Scales,
  onPress: (colName: keyof RulesItem) => void,
  fieldOrderedBy: keyof RulesItem
) => {
  return [
    <TableDescendingSortHeader
      key={"RuleIdTitle"}
      text={RULES_TABLE_ID[selectedLanguage]}
      onPress={() => onPress("ruleId")}
      isSorted={fieldOrderedBy === "ruleId"}
      containerStyle={{
        justifyContent: "flex-start",
        paddingLeft: scaleFunctions.moderateScale(5, 0.2)
      }}
      isBold={true}
    />,
    <TableDescendingSortHeader
      key={"RuleLabelTitle"}
      text={RULES_TABLE_NAME[selectedLanguage]}
      onPress={() => onPress("ruleLabel")}
      isSorted={fieldOrderedBy === "sort"}
      containerStyle={{
        justifyContent: "flex-start",
        paddingLeft: scaleFunctions.moderateScale(7, 0.2)
      }}
      isBold={true}
    />,
    <TableDescendingSortHeader
      key={"veryHeader"}
      text={RANK_TRANS.VERY_HIGH[selectedLanguage]}
      onPress={() => onPress("veryHighHits")}
      isSorted={fieldOrderedBy === "veryHighHits"}
      textStyle={styles(colors, scaleFunctions).veryHighHits}
      isBold={true}
    />,
    <TableDescendingSortHeader
      key={"highHeader"}
      text={RANK_TRANS.HIGH[selectedLanguage]}
      onPress={() => onPress("highHits")}
      isSorted={fieldOrderedBy === "highHits"}
      textStyle={styles(colors, scaleFunctions).highHits}
      isBold={true}
    />
  ];
};

type RulesTableComponentProps = {
  rulesItems: RulesItem[];
  selectedLanguage: string;
  onTitlePress: (id: string) => void;
  fieldOrderedBy: keyof RulesItem;
};

export const RulesTableComponent = ({
  rulesItems,
  selectedLanguage,
  onTitlePress,
  fieldOrderedBy
}: RulesTableComponentProps) => {
  const { colors } = React.useContext(ThemeContext);
  const tableBody: string[][] = [];
  const scaleFunctions = useScale();

  rulesItems.forEach((item: RulesItem) => {
    const veryHigh = formatPercentages(item.veryHighHits, selectedLanguage);
    const high = formatPercentages(item.highHits, selectedLanguage);
    tableBody.push([
      item.ruleId,
      item.ruleLabel[selectedLanguage],
      veryHigh,
      high
    ]);
  });

  const renderRow = (item: string, idx: number) => (
    <Cell
      key={"calTable" + idx}
      data={item}
      textStyle={[
        styles(colors, scaleFunctions).textStyles,
        { fontWeight: idx > 1 ? "bold" : "normal" }
      ]}
      style={[
        {
          flex: flexArr[idx],
          backgroundColor: idx % 2 === 0 ? colors.oddValue : colors.evenValue,
          height: scaleFunctions.moderateScale(...TABLE_LINE_HEIGHT_ARGS)
        },
        idx > 1
          ? styles(colors, scaleFunctions).cellRight
          : styles(colors, scaleFunctions).cellLeft
      ]}
    />
  );

  const renderRows = (row: string[], index: number) => (
    <TableWrapper
      key={"tableWrapperRule" + index}
      style={[{ flexDirection: "row" }, styles(colors, scaleFunctions).borders]}
    >
      {row.map(renderRow)}
    </TableWrapper>
  );

  return (
    <Table style={{ flex: 1, overflow: "hidden" }}>
      <Row
        data={getTableHead(
          selectedLanguage,
          colors,
          scaleFunctions,
          onTitlePress,
          fieldOrderedBy
        )}
        flexArr={headerFlexArr}
        textStyle={styles(colors, scaleFunctions).textStyles}
        style={[
          {
            backgroundColor: colors.oddHeader,
            height: scaleFunctions.moderateScale(...TABLE_LINE_HEIGHT_ARGS)
          }
        ]}
      />

      <View
        // Typescript is complaining because "auto" does not exist in Native but
        // by checking the platform before using it we can ensure it works.
        style={{ flex: 1, overflow: Platform.OS === "web" ? "auto" : "scroll" }}
      >
        {rulesItems.length === 0
          ? NO_DATA_ROW.map(renderRows)
          : tableBody.map(renderRows)}
      </View>
    </Table>
  );
};
