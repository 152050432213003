import { of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { getRules, RulesApiParams } from "../apis/RulesApi";
import handleError from "../utils/handleError";

class RulesService {
  callRules = (params: RulesApiParams) => {
    return getRules(params).pipe(
      map(res => {
        return {
          ...res.response
        };
      }),
      catchError(err => {
        handleError(err);
        return of(err);
      })
    );
  };
}

export default new RulesService();
