import React, { useContext } from "react";
import { RiskOverviewData } from "../types/apiTypes";
import { SettingsState } from "../types/storesTypes";
import { ThemeContext } from "../context/ThemeContext";
import {
  RiskKpiEnum,
  NO_DATA_NUMBER,
  SEMAPHORE_COLORS,
  RISK_DETAIL,
  RankColorEnum
} from "../constants/AppConstants";
import { View, StyleSheet, TouchableOpacity } from "react-native";
import {
  Table,
  Row,
  Cell,
  TableWrapper
} from "@deb-95/react-native-table-component";
import {
  KPI_NAMES,
  BODY_HEADER_TITLE,
  RANK_TRANS,
  BODY_HEADER_WGT
} from "../constants/Dictionary";
import { Avatar, Icon } from "react-native-elements";
import {
  shrinkCurrencyFormat,
  formattingCount,
  formattingAmount
} from "../utils/num_formatting";
import { useNavigation } from "@react-navigation/native";
import AppText from "./text/AppText";
import settingsStore from "../stores/settingsStore";
import {
  TABLE_HEADER_HEIGHT,
  OVERVIEW_TABLE_ROWS_HEIGHT,
  OVERVIEW_TABLE_FIRST_COLUMN_TEXT,
  MEDIUM_FONT,
  FONT_SIZE_BASE,
  FONT_SIZE_BASE_LITE
} from "../constants/StyleConstants";
import useScale, { Scales } from "../hooks/useScale";
import { VPayColors } from "../types/appTypes";
import { ScrollView } from "react-native-gesture-handler";

type RiskTableProps = {
  riskApi: RiskOverviewData;
  settingsState: SettingsState;
};

const NEXT_ICON_WIDTH = 20;

const riskTableStyles = (colors: VPayColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    container: {
      flex: 1
    },
    childContainer: {
      flex: 1,
      flexWrap: "nowrap",
      flexDirection: "row",
      minHeight: moderateScale(25, 0.4),
      maxHeight: moderateScale(...OVERVIEW_TABLE_ROWS_HEIGHT)
    },
    headLayout: {
      backgroundColor: colors.tableHeader,
      justifyContent: "center",
      height: moderateScale(...TABLE_HEADER_HEIGHT)
    },
    headTitle: {
      ...MEDIUM_FONT,
      textAlign: "right",
      color: colors.defaultText,
      paddingRight: moderateScale(4, 0.2),
      fontSize: moderateScale(...FONT_SIZE_BASE_LITE)
    },
    headValueLayout: {
      alignItems: "flex-end",
      justifyContent: "flex-end",
      backgroundColor: colors.oddHeader,
      paddingBottom: moderateScale(4, 0.2)
    },
    headerValuesEven: {
      backgroundColor: colors.evenHeader
    },
    headerValuesOdd: {
      backgroundColor: colors.oddHeader
    },
    firstColLayout: {
      alignItems: "flex-start",
      justifyContent: "center",
      paddingBottom: 0
    },
    firstCol1: {
      width: moderateScale(35, 0.2),
      alignItems: "center"
    },
    tableEven: { backgroundColor: colors.evenValue },
    tableOdd: { backgroundColor: colors.oddValue },
    tableText: {
      ...MEDIUM_FONT,
      color: colors.defaultText,
      fontSize: moderateScale(...FONT_SIZE_BASE)
    },
    subColumn2: {
      flex: 1,
      justifyContent: "center"
    },
    doubleColumnParent: {
      flexDirection: "row"
    },
    cell: { justifyContent: "flex-end", alignItems: "flex-end" },
    cellDefPaddingRx: { paddingRight: moderateScale(4, 0.2) },
    cellIconPaddingRx: { paddingRight: moderateScale(5, 0.2) },
    cellLastPaddingRx: { paddingRight: moderateScale(NEXT_ICON_WIDTH, 0.2) },
    rowBorder: {
      borderBottomWidth: 1,
      borderBottomColor: colors.tableRowBorder
    }
  });

const RiskTable = ({ riskApi, settingsState }: RiskTableProps) => {
  const { colors } = useContext(ThemeContext);
  const scaleFunctions = useScale();
  const { moderateScale } = scaleFunctions;
  const navigation = useNavigation();
  const isAmount = settingsState.selectedKpiFilter === RiskKpiEnum.AMOUNT;
  const titles = [
    "",
    BODY_HEADER_TITLE[settingsState.selectedLanguage],
    KPI_NAMES[settingsState.selectedKpiFilter][settingsState.selectedLanguage],
    <AppText
      key={"WgtTitle"}
      style={[
        riskTableStyles(colors, scaleFunctions).headTitle,
        riskTableStyles(colors, scaleFunctions).cellLastPaddingRx
      ]}
    >
      {`${BODY_HEADER_WGT[settingsState.selectedLanguage]} %`}
    </AppText>
  ];

  const geoLabelTxt =
    settingsStore.getGeoFilter(settingsState.selectedGeoFilter)?.labelLong[
      settingsState.selectedLanguage
    ] || "";
  const headValues = [
    <View
      key={"TableValueAvatar"}
      style={riskTableStyles(colors, scaleFunctions).doubleColumnParent}
    >
      <View
        key={"TableTitle1"}
        style={riskTableStyles(colors, scaleFunctions).firstCol1}
      >
        <Avatar
          size={moderateScale(25, 0.2)}
          rounded
          source={{ uri: riskApi.table.head.url }}
        />
      </View>
      <View
        style={riskTableStyles(colors, scaleFunctions).subColumn2}
        key={"TableTitle2"}
      >
        <AppText
          style={[
            {
              textTransform: "capitalize",
              fontSize: moderateScale(...OVERVIEW_TABLE_FIRST_COLUMN_TEXT)
            }
          ]}
        >
          {geoLabelTxt}
        </AppText>
      </View>
    </View>,
    shrinkCurrencyFormat(
      riskApi.table.head.avgAmount,
      settingsState.selectedLanguage
    ),
    isAmount
      ? shrinkCurrencyFormat(
          riskApi.table.head.amount,
          settingsState.selectedLanguage
        )
      : formattingCount(
          riskApi.table.head.count,
          settingsState.selectedLanguage
        ),
    riskApi.graphRisk === null
      ? NO_DATA_NUMBER
      : formattingAmount(100.0, settingsState.selectedLanguage)
  ];

  const bodyRows: Array<Array<string | number | JSX.Element>> = [];
  const links: Array<boolean> = [];

  riskApi.table.items.forEach((item, index) => {
    const hasLink = item.id.includes("HIGH") && item.count > 0;
    links.push(hasLink);
    const avgAmount = shrinkCurrencyFormat(
      item.avgAmount,
      settingsState.selectedLanguage
    );
    const countOrAmount = isAmount
      ? shrinkCurrencyFormat(item.amount, settingsState.selectedLanguage)
      : formattingCount(item.count, settingsState.selectedLanguage);
    const itemWeight = isAmount ? item.weightAmount : item.weightCount;
    const weight =
      itemWeight !== null ? (
        item.id.includes("HIGH") && item.count > 0 ? (
          <View
            style={[
              riskTableStyles(colors, scaleFunctions).cell,
              riskTableStyles(colors, scaleFunctions).childContainer
            ]}
          >
            <AppText style={riskTableStyles(colors, scaleFunctions).tableText}>
              {formattingAmount(itemWeight, settingsState.selectedLanguage)}
            </AppText>
            <Icon
              name={"chevron-thin-right"}
              type={"entypo"}
              size={moderateScale(12, 0.2)}
              color={colors.nextIconColor}
              containerStyle={[
                riskTableStyles(colors, scaleFunctions).cell,
                riskTableStyles(colors, scaleFunctions).cellIconPaddingRx,
                { width: moderateScale(NEXT_ICON_WIDTH, 0.2) }
              ]}
            />
          </View>
        ) : (
          <AppText
            style={[
              riskTableStyles(colors, scaleFunctions).tableText,
              riskTableStyles(colors, scaleFunctions).cellLastPaddingRx
            ]}
          >
            {formattingAmount(itemWeight, settingsState.selectedLanguage)}
          </AppText>
        )
      ) : (
        NO_DATA_NUMBER
      );

    bodyRows.push([
      <View
        key={`RowIcon${index}`}
        style={[riskTableStyles(colors, scaleFunctions).doubleColumnParent]}
      >
        <View style={riskTableStyles(colors, scaleFunctions).firstCol1}>
          <Icon
            name="fiber-manual-record"
            size={moderateScale(30, 0.2)}
            color={SEMAPHORE_COLORS[index]}
            type="material"
          />
        </View>
        <View style={riskTableStyles(colors, scaleFunctions).subColumn2}>
          <AppText
            style={{
              textTransform: "capitalize",
              fontSize: moderateScale(...OVERVIEW_TABLE_FIRST_COLUMN_TEXT)
            }}
          >
            {RANK_TRANS[RankColorEnum[index]][settingsState.selectedLanguage]}
          </AppText>
        </View>
      </View>,
      avgAmount,
      countOrAmount,
      weight
    ]);
  });

  const columnsGrows = [3, 2.5, 2.5, 2];

  return (
    <View style={riskTableStyles(colors, scaleFunctions).container}>
      <Table style={riskTableStyles(colors, scaleFunctions).container}>
        <Row
          data={titles}
          style={[
            riskTableStyles(colors, scaleFunctions).headLayout,
            riskTableStyles(colors, scaleFunctions).rowBorder
          ]}
          textStyle={[riskTableStyles(colors, scaleFunctions).headTitle]}
          flexArr={columnsGrows}
        />
        <TableWrapper
          style={[
            riskTableStyles(colors, scaleFunctions).rowBorder,
            riskTableStyles(colors, scaleFunctions).childContainer,
            { flex: 1.9 }
          ]}
        >
          {headValues.map((headValue, index) => (
            <Cell
              key={index}
              data={headValue}
              style={[
                { flex: columnsGrows[index] },
                riskTableStyles(colors, scaleFunctions).headValueLayout,
                index % 2 === 0
                  ? riskTableStyles(colors, scaleFunctions).headerValuesEven
                  : riskTableStyles(colors, scaleFunctions).headerValuesOdd,
                index === 0 &&
                  riskTableStyles(colors, scaleFunctions).firstColLayout
              ]}
              textStyle={[
                riskTableStyles(colors, scaleFunctions).tableText,
                index === headValues.length - 1
                  ? riskTableStyles(colors, scaleFunctions).cellLastPaddingRx
                  : riskTableStyles(colors, scaleFunctions).cellDefPaddingRx
              ]}
            />
          ))}
        </TableWrapper>
        <ScrollView
          showsVerticalScrollIndicator={false}
          contentContainerStyle={{
            flexGrow: 1,
            flex: 1,
            flexDirection: "column"
          }}
        >
          {bodyRows.map((row, rowIndex) => (
            <TouchableOpacity
              onPress={() =>
                navigation.navigate(RISK_DETAIL, {
                  riskRank: riskApi.table.items[rowIndex].id,
                  settingsStateParam: settingsState
                })
              }
              disabled={!links[rowIndex]}
              key={"tableWrapper" + rowIndex}
              style={[
                riskTableStyles(colors, scaleFunctions).rowBorder,
                riskTableStyles(colors, scaleFunctions).childContainer
              ]}
            >
              {row.map((cellValue, cellIndex) => (
                <Cell
                  key={"tableWrapperCell" + cellIndex}
                  data={cellValue}
                  style={[
                    { flex: columnsGrows[cellIndex] },
                    riskTableStyles(colors, scaleFunctions).headValueLayout,
                    cellIndex % 2 === 0
                      ? riskTableStyles(colors, scaleFunctions).tableEven
                      : riskTableStyles(colors, scaleFunctions).tableOdd,
                    cellIndex === 0 &&
                      riskTableStyles(colors, scaleFunctions).firstColLayout
                  ]}
                  textStyle={[
                    riskTableStyles(colors, scaleFunctions).tableText,
                    cellIndex === row.length - 1
                      ? riskTableStyles(colors, scaleFunctions)
                          .cellLastPaddingRx
                      : riskTableStyles(colors, scaleFunctions).cellDefPaddingRx
                  ]}
                />
              ))}
            </TouchableOpacity>
          ))}
        </ScrollView>
      </Table>
    </View>
  );
};

export default RiskTable;
