import React, { useContext } from "react";
import { Platform, View } from "react-native";

import * as array from "d3-array";
import * as shape from "d3-shape";
import * as scale from "d3-scale";
import { StackedBarChart, YAxis, XAxis, Grid } from "react-native-svg-charts";
import { SettingsState } from "../types/storesTypes";

import {
  RiskDetailData,
  PaymentCountGraphEnum,
  PaymentAmountGraphEnum,
  PaymentData
} from "../types/apiTypes";
import { RiskKpiEnum } from "../constants/AppConstants";
import { ThemeContext } from "../context/ThemeContext";
import { formatGraphData } from "../utils/num_formatting";
import useScale from "../hooks/useScale";
import { ROBOTO } from "../constants/StyleConstants";

type RiskDetailGraphProps = {
  settingsState: SettingsState;
  riskDetailApi: RiskDetailData;
};

const RiskDetailGraph = ({
  settingsState,
  riskDetailApi
}: RiskDetailGraphProps) => {
  const { colors } = useContext(ThemeContext);
  const { moderateScale } = useScale();

  const barColors = [colors.alertBar, colors.toCheckBar];
  const labelsFontSize = moderateScale(7.9, 0.3);
  const axesSvg = {
    fontSize: labelsFontSize,
    fill: colors.graphLabels,
    fontFamily: ROBOTO
  };
  const verticalContentInset = { top: 10, bottom: 10 };
  const xAxisHeight = 10;
  const spacingInner = 0.6;
  const spacingOuter = 1;
  const numberOfTicks = 5;
  const maxColumnWidth = 70;

  const countKeys: Array<string> = Object.values(PaymentCountGraphEnum);
  const amountKeys: Array<string> = Object.values(PaymentAmountGraphEnum);
  const graphKeys =
    settingsState.selectedKpiFilter === RiskKpiEnum.AMOUNT
      ? amountKeys
      : countKeys;

  const getGraphData = () => {
    const graphData: {
      labels: string[];
      data: Array<PaymentData>;
    } = { labels: [], data: [] };

    riskDetailApi.table.items.forEach(item => {
      const currentItemData = { ...item.data };
      graphData.labels.push(item.geo.split("_")[1] || item.geo);
      graphData.data.push(currentItemData);
    });
    return graphData;
  };

  const graphData = getGraphData();

  const renderStackedBarChart = () => {
    const castedGraphKeys = graphKeys as (
      | PaymentCountGraphEnum
      | PaymentAmountGraphEnum
    )[];
    return (
      <StackedBarChart
        style={{ flex: 1 }}
        keys={[...castedGraphKeys] as const}
        colors={barColors}
        data={graphData.data}
        contentInset={verticalContentInset}
        showGrid={true}
        spacingInner={spacingInner}
        spacingOuter={spacingOuter}
        numberOfTicks={numberOfTicks}
        // prop added manually with a patch in react native svg package
        maxColumnWidth={maxColumnWidth}
      >
        <Grid svg={{ stroke: "grey" }} />
      </StackedBarChart>
    );
  };

  const extractDataPoints = (
    data: Array<PaymentData>,
    dataKeys: string[],
    order = shape.stackOrderNone,
    offset = shape.stackOffsetNone
  ) => {
    const series = shape
      .stack()
      .keys(dataKeys)
      .order(order)
      .offset(offset)(data);

    //double merge arrays to extract just the values
    return array.merge(array.merge(series));
  };

  const renderYAxis = () => {
    return (
      <YAxis
        data={extractDataPoints(graphData.data, graphKeys)}
        numberOfTicks={numberOfTicks}
        style={{ marginBottom: xAxisHeight, marginHorizontal: 2, minWidth: 20 }}
        contentInset={verticalContentInset}
        svg={axesSvg}
        formatLabel={(value: string) =>
          `${formatGraphData(
            parseInt(value, 10),
            settingsState.selectedLanguage
          )}`
        }
      />
    );
  };

  const renderXAxis = () => {
    return (
      <XAxis
        data={graphData.labels}
        style={{ height: xAxisHeight }}
        spacingInner={spacingInner}
        spacingOuter={spacingOuter}
        scale={scale.scaleBand}
        svg={{
          ...axesSvg,
          dy:
            Platform.OS === "web" && navigator.userAgent.includes("Firefox")
              ? labelsFontSize
              : 0
        }}
        formatLabel={(_, index) => graphData.labels[index]}
        // prop added manually with a patch in react native svg package
        maxColumnWidth={maxColumnWidth}
      />
    );
  };

  return (
    <View
      style={[
        {
          flex: 1,
          flexDirection: "row",
          paddingVertical: 5,
          paddingLeft: 5,
          paddingRight: 10
        }
      ]}
    >
      {renderYAxis()}
      <View style={{ flex: 1 }}>
        {renderStackedBarChart()}
        {renderXAxis()}
      </View>
    </View>
  );
};

export default RiskDetailGraph;
