import React, { useContext } from "react";
import { View, StyleSheet } from "react-native";
import { Icon } from "react-native-elements";
import { ThemeContext } from "../context/ThemeContext";
import useScale from "../hooks/useScale";

const dataLoadsStatusStyle = StyleSheet.create({
  dataLoadsStatusContainer: {
    display: "flex",
    flex: 0.7,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    maxWidth: 40
  }
});

const DOT_CONTAINER_SIZE = 6;

const MAX_NUMBER_OF_FILLED_CIRCLES = 4;

const getNumberOfLoads = (date: string) => {
  const parsedDate = new Date(date);
  const currentHour = parsedDate.getUTCHours();
  const currentMinutes = parsedDate.getUTCMinutes();
  if (currentHour < 6 || (currentHour === 6 && currentMinutes < 30)) {
    return 0;
  }
  if (currentHour < 10 || (currentHour === 10 && currentMinutes < 30)) {
    return 1;
  }
  if (currentHour < 12 || (currentHour === 12 && currentMinutes < 30)) {
    return 2;
  }
  if (currentHour < 15 || (currentHour === 15 && currentMinutes < 30)) {
    return 3;
  }
  return MAX_NUMBER_OF_FILLED_CIRCLES;
};

type DataLoadsStatusProps = {
  launchDate: string;
};

const DataLoadsStatus = ({ launchDate }: DataLoadsStatusProps) => {
  const numberOfLoads = getNumberOfLoads(launchDate);
  const { colors } = useContext(ThemeContext);
  const { moderateScale } = useScale();
  return (
    <View style={dataLoadsStatusStyle.dataLoadsStatusContainer}>
      {Array.from({ length: numberOfLoads }, (_, i) => (
        <Icon
          key={`FilledCircleIcon${i}`}
          name="controller-record"
          type="entypo"
          color={colors.filledDot}
          size={moderateScale(DOT_CONTAINER_SIZE, 0.2)}
        />
      ))}
      {Array.from(
        { length: MAX_NUMBER_OF_FILLED_CIRCLES - numberOfLoads },
        (_, i) => (
          <Icon
            key={`BlankCircleIcon${i}`}
            name="controller-record"
            type="entypo"
            color={colors.emptyDot}
            size={moderateScale(DOT_CONTAINER_SIZE, 0.2)}
          />
        )
      )}
    </View>
  );
};

export default DataLoadsStatus;
