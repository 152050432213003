import "react-native-gesture-handler";
import { Asset } from "expo-asset";
import React from "react";
import * as Font from "expo-font";
import { AppLoading } from "expo";
import { Ionicons } from "@expo/vector-icons";

import ThemeProvider from "./src/components/ThemeProvider";
import Main from "./src/components/Main";
import settingsStore from "./src/stores/settingsStore";
import { GENERIC_ERROR_MESSAGE } from "./src/constants/Dictionary";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  ROBOTO,
  ROBOTO_BOLD,
  ROBOTO_ITALIC,
  ROBOTO_MEDIUM
} from "./src/constants/StyleConstants";

const customFonts = {
  [ROBOTO]: require("./assets/fonts/Roboto-Regular.ttf"),
  [ROBOTO_MEDIUM]: require("./assets/fonts/Roboto-Medium.ttf"),
  [ROBOTO_BOLD]: require("./assets/fonts/Roboto-Bold.ttf"),
  [ROBOTO_ITALIC]: require("./assets/fonts/Roboto-Italic.ttf")
};

async function loadResourcesAsync() {
  await Promise.all([
    Asset.loadAsync([
      require("./assets/images/flags/en.png"),
      require("./assets/images/flags/it.png")
    ]),
    Font.loadAsync({
      ...Ionicons.font,
      ...customFonts
    })
  ]);
}

export default class App extends React.Component {
  state = {
    isLoadingComplete: false
  };

  static getDerivedStateFromError(error: Error) {
    settingsStore.setError(
      true,
      GENERIC_ERROR_MESSAGE[settingsStore.getSelectedLanguage()],
      error.message
    );
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    if (!this.state.isLoadingComplete) {
      return (
        <AppLoading
          startAsync={loadResourcesAsync}
          onFinish={() => this.setState({ isLoadingComplete: true })}
        />
      );
    } else {
      return (
        <SafeAreaProvider>
          <ThemeProvider>
            <Main />
          </ThemeProvider>
        </SafeAreaProvider>
      );
    }
  }
}
