import Constants from "expo-constants";
import {
  IS_LOCAL,
  LOCAL_STORAGE_TOKEN_NAME,
  TOKEN_HEADER_NAME
} from "../constants/AppConstants";

export const API_ENDPOINT = IS_LOCAL()
  ? Constants.manifest.extra?.ENDPOINT
  : `${window.location.origin}/api/`;

export const APPINSIGHTS_KEY = Constants.manifest.extra?.APPINSIGHTS_KEY
  ? Constants.manifest.extra?.APPINSIGHTS_KEY
  : `undefined`;

export const BASE_HEADERS = {
  Accept: "application/json",
  "Content-Type": "application/json"
};

const BASE_SETTINGS = {
  withCredentials: true,
  crossDomain: false
};
export const API_SETTINGS = {
  ...(!IS_LOCAL() ? BASE_SETTINGS : {}),
  headers: {
    ...BASE_HEADERS,
    ...(IS_LOCAL()
      ? { [TOKEN_HEADER_NAME]: localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME) }
      : {})
  }
};
