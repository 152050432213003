import React, { useState, useEffect } from "react";
import { View, StyleSheet, Switch, Platform, ScrollView } from "react-native";
import { ListItem, Button } from "react-native-elements";
import { Card } from "react-native-elements";
import Constants from "expo-constants";

import SettingsService from "../services/SettingsService";
import settingsStore from "../stores/settingsStore";
import {
  FE_INFO_TITLE,
  BE_INFO_TITLE,
  VERSION,
  LAUNCHDATE,
  CUTDATE,
  ADV_PREFERENCES,
  REFRESH,
  REAL_TIME,
  DATA_VERSION
} from "../constants/Dictionary";
import { Info } from "../types/apiTypes";
import { ThemeContext } from "../context/ThemeContext";
import AppText from "../components/text/AppText";
import { formatDate } from "../utils/num_formatting";
import useSettingsState from "../hooks/useSettingsState";
import LoadingView from "../components/LoadingView";
import useScale, { ScaleFunctionArgs, Scales } from "../hooks/useScale";
import { VPayColors } from "../types/appTypes";
import { ROBOTO } from "../constants/StyleConstants";

const titleFontSize: ScaleFunctionArgs = [12, 0.2];
const iconSize: ScaleFunctionArgs = [12, 0.2];

const infosStyle = (colors: VPayColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    advancedScreen: {
      flex: 1,
      backgroundColor: colors.screenBackgroundColor
    },
    advancedCard: {
      backgroundColor: colors.screenBackgroundColor
    },
    advancedText: {
      color: colors.defaultText,
      fontFamily: ROBOTO,
      fontSize: moderateScale(...titleFontSize),
      paddingLeft: moderateScale(10, 0.2)
    },
    preference: {
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "flex-start"
    },
    langPreference: {
      flexDirection: "column",
      paddingVertical: 12,
      paddingHorizontal: 16
    },
    refreshButtonContainer: {
      backgroundColor: colors.tintColor,
      margin: 10
    },
    refreshButtonTitle: {
      color: colors.whiteText
    },
    listItemContainerStyle: { backgroundColor: colors.screenBackgroundColor },
    background: { backgroundColor: colors.screenBackgroundColor },
    divider: { backgroundColor: colors.defaultText }
  });

const AdvancedScreen = () => {
  const [infoApi, setInfoApi] = useState<Info>();
  const { colors } = React.useContext(ThemeContext);
  const settingsState = useSettingsState();
  const scaleFunctions = useScale();

  useEffect(() => {
    const callInfoSubscription = SettingsService.callInfo().subscribe(
      setInfoApi
    );
    return () => {
      callInfoSubscription.unsubscribe();
    };
  }, []);

  if (!settingsState) {
    return <LoadingView />;
  }

  const { selectedLanguage, realTime, user } = settingsState;

  const RTSwitch = () => {
    return (
      <View
        style={[infosStyle(colors, scaleFunctions).preference, { flex: 1 }]}
      >
        <AppText
          style={[
            infosStyle(colors, scaleFunctions).advancedCard,
            infosStyle(colors, scaleFunctions).advancedText,
            { flexGrow: 1 }
          ]}
        >
          {REAL_TIME[selectedLanguage]}
        </AppText>
        <View style={{ flexGrow: 1 }}>
          <Switch
            value={realTime}
            onValueChange={settingsStore.toggleRealTime}
          />
        </View>
      </View>
    );
  };

  const RefreshButton = () => {
    return (
      <Button
        buttonStyle={infosStyle(colors, scaleFunctions).refreshButtonContainer}
        titleStyle={infosStyle(colors, scaleFunctions).refreshButtonTitle}
        title={REFRESH[selectedLanguage]}
        onPress={() => {
          location.reload(true);
          caches.keys().then(function(names) {
            for (const name of names) {
              caches.delete(name);
            }
          });
        }}
      />
    );
  };

  const sysUpdDate = new Date(infoApi?.launchDate as string);
  const cutDate = new Date(infoApi?.cutDate as string);

  type Card = {
    head: { title: string };
    items: Array<CardItem>;
  };

  type CardItem = {
    title: string;
    icon: string;
    type: string;
  };

  const renderAdvancedSettingsCard = () => {
    return (
      <Card
        title={ADV_PREFERENCES[selectedLanguage]}
        titleStyle={infosStyle(colors, scaleFunctions).advancedText}
        wrapperStyle={infosStyle(colors, scaleFunctions).advancedCard}
        containerStyle={infosStyle(colors, scaleFunctions).advancedCard}
        dividerStyle={infosStyle(colors, scaleFunctions).divider}
      >
        {user.admin && <RTSwitch />}
        {Platform.OS === "web" && <RefreshButton />}
      </Card>
    );
  };

  const cards: Array<Card> = [
    {
      head: { title: FE_INFO_TITLE[selectedLanguage] },
      items: [
        {
          title: `${VERSION[selectedLanguage]}: ${Constants.manifest.version}`,
          icon: "versions",
          type: "octicon"
        }
      ]
    },
    {
      head: { title: BE_INFO_TITLE[selectedLanguage] },
      items: [
        {
          title: `${DATA_VERSION[selectedLanguage]}: ${infoApi?.version}`,
          icon: "versions",
          type: "octicon"
        },
        {
          title: `${LAUNCHDATE[selectedLanguage]}: ${formatDate(
            sysUpdDate,
            selectedLanguage
          )}`,
          icon: "launch",
          type: "material"
        },
        {
          title: `${CUTDATE[selectedLanguage]}: ${formatDate(
            cutDate,
            selectedLanguage,
            false
          )}`,
          icon: "erase",
          type: "entypo"
        }
      ]
    }
  ];

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={infosStyle(colors, scaleFunctions).advancedScreen}
    >
      {renderAdvancedSettingsCard()}
      {cards.map((card, i) => (
        <Card
          title={card.head.title}
          titleStyle={infosStyle(colors, scaleFunctions).advancedText}
          key={i}
          wrapperStyle={infosStyle(colors, scaleFunctions).advancedCard}
          containerStyle={infosStyle(colors, scaleFunctions).advancedCard}
          dividerStyle={infosStyle(colors, scaleFunctions).divider}
        >
          {card.items.map((el: CardItem, j: number) => (
            <ListItem
              key={j}
              title={el.title}
              titleStyle={[infosStyle(colors, scaleFunctions).advancedText]}
              containerStyle={infosStyle(colors, scaleFunctions).advancedCard}
              contentContainerStyle={[
                infosStyle(colors, scaleFunctions).background,
                infosStyle(colors, scaleFunctions).advancedText
              ]}
              leftIcon={{
                name: el.icon,
                type: el.type,
                size: scaleFunctions.moderateScale(...iconSize),
                color: colors.defaultText
              }}
            />
          ))}
        </Card>
      ))}
    </ScrollView>
  );
};

export default AdvancedScreen;
