import { NO_DATA_NUMBER } from "../../constants/AppConstants";

export const formattingAmount = (numberToFormat: number, language: string) => {
  return numberToFormat !== null && numberToFormat !== undefined
    ? new Intl.NumberFormat(language, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
        useGrouping: true
      }).format(numberToFormat)
    : NO_DATA_NUMBER;
};

export const formattingCount = (numberToFormat: number, language: string) => {
  return numberToFormat !== null && numberToFormat !== undefined
    ? numberToFormat.toLocaleString(language)
    : NO_DATA_NUMBER;
};

export const formatDate = (date: Date, language: string, withHour = true) => {
  const fullDate = date.toLocaleString(language, {
    year: "numeric",
    month: "numeric",
    day: "numeric"
  });
  const hours = withHour
    ? date.toLocaleTimeString(language, {
        hour: "2-digit",
        minute: "2-digit"
      })
    : "";
  return `${fullDate} ${hours}`.trim();
};

function roundNumberWithPrecision(value: number, precision?: number) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

const delUnsignificant = (num: number) => {
  if (num % 1 <= 0.5) {
    return Math.floor(num).toLocaleString("it");
  } else return num.toLocaleString("it");
};

export const shrinkCurrencyFormat = (num: number, language: string) => {
  if (num !== null) {
    let num2: number;
    if (Math.abs(num) > 999) {
      num2 = roundNumberWithPrecision(num / 1000);
      return num2.toLocaleString(language) + " K";
    } else {
      num2 = roundNumberWithPrecision(num);
      return delUnsignificant(num2);
    }
  }
  return NO_DATA_NUMBER;
};

export const formatGraphData = (numberToFormat: number, language: string) => {
  const suffixes = ["", "K", "M", "B"];
  if (numberToFormat !== null && numberToFormat !== undefined) {
    if (Math.abs(numberToFormat) < 999) {
      return numberToFormat;
    }
    const groups = Math.floor(Math.log(numberToFormat) / Math.log(1000));
    const adjustedGroups =
      groups < suffixes.length ? groups : suffixes.length - 1;
    const divider = Math.pow(1000, adjustedGroups);
    const shortenedNumber = roundNumberWithPrecision(
      numberToFormat / divider,
      1
    );
    return `${shortenedNumber.toLocaleString(language)}${
      suffixes[adjustedGroups]
    }`;
  }
  return NO_DATA_NUMBER;
};

export const formatPercentages = (
  numberToFormat: number,
  selectedLanguage: string
) => {
  return numberToFormat !== 0
    ? formattingAmount(numberToFormat, selectedLanguage).concat(" %")
    : NO_DATA_NUMBER;
};
