import React from "react";
import { View, StyleSheet } from "react-native";
import { SettingsState } from "../types/storesTypes";
import settingsStore from "../stores/settingsStore";
import { FROM, TO } from "../constants/Dictionary";
import DataLoadsStatus from "./DataLoadsStatus";
import { convertDate } from "../constants/HelperFunctions";
import { ThemeContext } from "../context/ThemeContext";
import { Response } from "../types/apiTypes";
import useScale, { Scales } from "../hooks/useScale";
import { VPayColors } from "../types/appTypes";
import AppText from "./text/AppText";
import { FONT_SIZE_BASE_LITE } from "../constants/StyleConstants";

const timeBarStyle = (colors: VPayColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    timeBarContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 10,
      paddingRight: 10,
      backgroundColor: colors.timeBarBackground,
      height: moderateScale(19, 0.2)
    },
    timeBarText: {
      flex: 7,
      color: colors.timeBarText,
      fontSize: moderateScale(...FONT_SIZE_BASE_LITE)
    }
  });

type TimeBarProps = {
  settingsState: SettingsState;
  apiData: Response;
};

const TimeBar = ({ settingsState, apiData }: TimeBarProps) => {
  const { selectedTimeFilter } = settingsState;
  const scaleFunctions = useScale();
  const activeTimeFilter = settingsStore.getTimeFilter(selectedTimeFilter);

  const gregFrom = convertDate(
    apiData.time.gregFrom,
    settingsState.selectedLanguage
  );
  const gregTo = convertDate(
    apiData.time.gregTo,
    settingsState.selectedLanguage
  );
  const yesterdayLabel = `${gregFrom}`;
  const otherPeriodsLabel = `${
    FROM[settingsState.selectedLanguage]
  } ${gregFrom} ${TO[settingsState.selectedLanguage]} ${gregTo}`;

  const periodString =
    selectedTimeFilter.toLowerCase() === "roll1"
      ? yesterdayLabel
      : otherPeriodsLabel;

  const { colors } = React.useContext(ThemeContext);

  return (
    <View style={timeBarStyle(colors, scaleFunctions).timeBarContainer}>
      <AppText style={timeBarStyle(colors, scaleFunctions).timeBarText}>
        {`${
          activeTimeFilter?.labelLong[settingsState.selectedLanguage]
        } ${periodString}`}
      </AppText>
      <DataLoadsStatus launchDate={apiData.time.launchDate} />
    </View>
  );
};

export default TimeBar;
