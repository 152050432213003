import React from "react";
import { VPayGlobalStore } from "../types/storesTypes";
import useMountEffect from "./useMountEffect";

const useGlobalState = <T>(
  store: VPayGlobalStore<T>,
  defaultState?: T
): T | undefined => {
  const [state, setState] = React.useState<T | undefined>(defaultState);
  useMountEffect(() => {
    const stateSubscription = store.subscribe(setState);
    return () => {
      stateSubscription.unsubscribe();
    };
  });
  return state;
};

export default useGlobalState;
