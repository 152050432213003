import { Observable } from "rxjs";
import { /*ajax,*/ AjaxResponse } from "rxjs/ajax";
import { Info, Settings } from "../types/apiTypes";
import { API_ENDPOINT, API_SETTINGS } from "./ApiBaseSettings";
import { Preferences, User } from "../types/storesTypes";
import {
  trackedAjaxGetJson,
  /*trackedAjaxGetJson,*/ trackedAjaxPostJson
} from "./AppInsigthsTracker";

export const getSettings = (): Observable<AjaxResponse<Settings>> => {
  return trackedAjaxGetJson<Settings>({
    ...API_SETTINGS,
    url: API_ENDPOINT
  });
};

export const getInfo = (): Observable<AjaxResponse<Info>> => {
  return trackedAjaxGetJson<Info>({
    ...API_SETTINGS,
    url: `${API_ENDPOINT}info`
  });
};

export const setPreferences = (
  preferences: Preferences
): Observable<AjaxResponse<void>> => {
  return trackedAjaxPostJson({
    ...API_SETTINGS,
    url: `${API_ENDPOINT}user/savePreferences`,
    method: "POST",
    body: preferences
  });
};

export const getUser = (): Observable<AjaxResponse<User>> => {
  return trackedAjaxGetJson<User>({
    ...API_SETTINGS,
    url: `${API_ENDPOINT}user`
  });
};
