import { Label } from "../types/apiTypes";

export const LANGUAGE: Label = {
  en: "Language",
  it: "Lingua"
};

export const PREFERENCES: Label = {
  en: "Preferences",
  it: "Preferenze"
};

/**
 * translations for InfoScreen.tsx
 */
export const ADV_PREFERENCES: Label = {
  en: "Advanced Preferences",
  it: "Impostazioni Avanzate"
};

export const REAL_TIME: Label = {
  en: "Technical Check",
  it: "Controllo Tecnico"
};

export const FE_INFO_TITLE: Label = {
  en: "Client app Information",
  it: "Info Applicazione Client"
};

export const BE_INFO_TITLE: Label = {
  en: "Server Information",
  it: "Info Server"
};

export const VERSION: Label = {
  en: "Client version",
  it: "Versione client"
};

export const DATA_VERSION: Label = {
  en: "Data id",
  it: "Id dati"
};

export const LAUNCHDATE: Label = {
  en: "System updated",
  it: "Sistema aggiornato"
};

export const CUTDATE: Label = {
  en: "Last available date",
  it: "Ultima data disponibile"
};
/**++++++++++++++++++++++++++++++++++++++++++++++++++*/

/**
 * translations for TimeBar
 */

export const REFRESH: Label = {
  en: "Refresh",
  it: "Aggiorna"
};

export const FROM: Label = {
  en: "from",
  it: "dal"
};

export const TO: Label = {
  en: "to",
  it: "al"
};

/**++++++++++++++++++++++++++++++++++++++++++++++++++*/

/**
 * translations for DataRefreshLog
 */

export const DAILYLOADS: Label = {
  en: "Daily Loads",
  it: "Scaricamenti Quotidiani"
};

/**
 * translations for BodyHeaderTitle
 */

export const BODY_HEADER_TITLE: Label = {
  en: "Avg Amount €",
  it: "Valore medio €"
};

export const BODY_HEADER_VALUES: Label = {
  en: "Σ Transaction",
  it: "Transazioni Σ"
};

export const BODY_HEADER_WGT: Label = {
  en: "Wgt",
  it: "Peso"
};

/**
 * translations for RiskDetailTableBodyHeaderTitle
 */
export const R_DET_BODY_HEADER_TITLE_ALRT: Label = {
  en: "Alerts",
  it: "Alerts"
};

export const R_DET_BODY_HEADER_TITLE_TBC: {
  [key: string]: Label;
} = {
  count: { en: "Tbc Σ", it: "Σ Tbc" },
  amount: { en: "Tbc €", it: "Tbc €" }
};
/**++++++++++++++++++++++++++++++++++++++++++++++++++*/

export const DARK_THEME: Label = {
  en: "Dark Theme",
  it: "Tema Scuro"
};

export const KPI_NAMES: { [key: string]: Label } = {
  count: { en: "Transaction Σ", it: "Σ Transazioni" },
  amount: { en: "Amount €", it: "Valore €" }
};

export const SESSION_EXPIRED_ERROR: Label = {
  en: "Your session has expired, please refresh.",
  it: "La tua sessione è scaduta, ricarica l'app."
};

export const ERROR_SUBTEXT: Label = {
  en: "Make sure you are connected to the internet and click the button below.",
  it:
    "Assicurati di essere connesso a Internet e fai clic sul pulsante in basso."
};

export const NOT_FOUND_ERROR: Label = {
  en: "Page not found",
  it: "Pagina non trovata"
};

export const RANK_TRANS: { [key: string]: Label } = {
  VERY_HIGH: { en: "Very High", it: "Molto Alto" },
  HIGH: { en: "High", it: "Alto" },
  MEDIUM: { en: "Medium", it: "Medio" },
  LOW: { en: "Low", it: "Basso" },
  VERY_LOW: { en: "Very Low", it: "Molto Basso" }
};

export const RELOAD: Label = {
  en: "Reload",
  it: "Ricarica"
};

export const GENERIC_ERROR_MESSAGE: Label = {
  en: "Something went wrong",
  it: "Qualcosa è andato storto"
};

export const OVERVIEW_GRAPH_RISK_LABEL: Label = {
  en: "Potential Risk",
  it: "Rischio Potenziale"
};

export const NO_DATA_TO_SHOW: Label = {
  en: "Data not available yet",
  it: "Dati non ancora disponibili"
};

export const rulesTransactionCount: (s: string) => Label = (
  additionalString: string
) => {
  return {
    en: `${additionalString} Transactions Σ`,
    it: `Σ Transazioni ${additionalString}`
  };
};

export const BANKS_TRANSACTIONS: Label = {
  en: `Trans. Σ`,
  it: `Σ Trans.`
};

export const RULES_TABLE_ID: Label = {
  en: "#",
  it: "#"
};

export const RULES_TABLE_NAME: Label = {
  en: "Rule Name",
  it: "Nome Regola"
};

export const GRAPH_BAR_LEGEND_LABELS: Array<Label> = [
  { en: "To be checked", it: "Da Verificare" },
  { en: "Alerts", it: "Avvisi" }
];

export const RULES_GRAPH_LEGEND_LABELS: Array<Label> = [
  { en: "% Very High", it: "% Molto Alto" },
  { en: "% High", it: "% Alto" }
];

export const RISK_SCREEN_LABEL: Label = {
  en: "Risk",
  it: "Rischio"
};
export const RISK_HEAD_TITLE: Label = {
  en: "Risk Overview",
  it: "Panoramica Rischio"
};

export const RULES_SCREEN_LABEL: Label = {
  en: "Rules",
  it: "Regole"
};
export const RULES_HEAD_TITLE: Label = {
  en: "Rules",
  it: "Regole"
};

export const BANKS_SCREEN_LABEL: Label = {
  en: "Banks",
  it: "Banche"
};
export const BANKS_HEAD_TITLE: Label = {
  en: "Banks",
  it: "Banche"
};

export const bankComposedLabel: (s: string) => Label = (
  additionalString: string
) => {
  return {
    en: `${additionalString} ${BANKS_HEAD_TITLE.en}`,
    it: `${BANKS_HEAD_TITLE.it} ${additionalString}`
  };
};

export const ALL_BANKS_PREFIX: Label = {
  en: "All",
  it: "Tutte le"
};

export const NO_RISK_TO_SHOW: Label = {
  en: "No risky transactions to show",
  it: "Nessuna transazione a rischio da visualizzare"
};

export const NO_DETAIL_TO_SHOW: Label = {
  en: "No alerts or unchecked transactions to show",
  it: "Nessuna transazione sospetta o da verificare"
};
