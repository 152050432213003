import { Row, Table } from "@deb-95/react-native-table-component";
import React, { ReactNode } from "react";
import { ThemeContext } from "../../context/ThemeContext";
import { StyleSheet } from "react-native";
import { RulesHead } from "../../types/apiTypes";
import { rulesTransactionCount } from "../../constants/Dictionary";
import { formattingCount } from "../../utils/num_formatting";
import useScale, { Scales } from "../../hooks/useScale";
import { VPayColors } from "../../types/appTypes";
import GeographyIconName from "../GeographyIconName";
import { TABLE_LINE_HEIGHT_ARGS } from "../../constants/AppConstants";
import { MEDIUM_FONT, FONT_SIZE_BASE } from "../../constants/StyleConstants";
import AppText from "../text/AppText";

const flexArr = [3.15, 1.1, 0.75];

type RulesTransactionCountProps = {
  rulesHead: RulesHead;
  selectedLanguage: string;
  selectedGeography: string;
};

const styles = (colors: VPayColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    textStyles: {
      color: colors.defaultText,
      fontSize: moderateScale(...FONT_SIZE_BASE)
    },
    numberTextStyle: {
      fontSize: moderateScale(...FONT_SIZE_BASE),
      textAlign: "right",
      paddingRight: moderateScale(7, 0.2),
      ...MEDIUM_FONT
    }
  });

const genTableHead = (
  rulesHead: RulesHead,
  colors: VPayColors,
  selectedLanguage: string,
  scaleFunctions: Scales,
  selectedGeography: string
): ReactNode[] => {
  return [
    <GeographyIconName
      key={"Transaction"}
      text={rulesTransactionCount(selectedGeography)[selectedLanguage]}
      iconUrl={rulesHead.url}
    />,
    <AppText
      key={"veryHeader"}
      style={[
        styles(colors, scaleFunctions).numberTextStyle,
        { color: colors.veryHighText }
      ]}
    >
      {formattingCount(rulesHead.veryHighCount, selectedLanguage)}
    </AppText>,
    <AppText
      key={"highHeader"}
      style={[
        styles(colors, scaleFunctions).numberTextStyle,
        { color: colors.highText }
      ]}
    >
      {formattingCount(rulesHead.highCount, selectedLanguage)}
    </AppText>
  ];
};

export const RulesTransactionCount = ({
  rulesHead,
  selectedLanguage,
  selectedGeography
}: RulesTransactionCountProps) => {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  return (
    <Table>
      <Row
        data={genTableHead(
          rulesHead,
          colors,
          selectedLanguage,
          scaleFunctions,
          selectedGeography
        )}
        flexArr={flexArr}
        textStyle={styles(colors, scaleFunctions).textStyles}
        style={{
          backgroundColor: colors.tableHeader,
          height: scaleFunctions.moderateScale(...TABLE_LINE_HEIGHT_ARGS),
          paddingLeft: scaleFunctions.moderateScale(5, 0.2)
        }}
      />
    </Table>
  );
};
