import { RiskOverviewData, RiskDetailData } from "../types/apiTypes";

import { Observable } from "rxjs";
import {
  API_ENDPOINT,
  API_SETTINGS /*, BASE_HEADERS*/
} from "./ApiBaseSettings";
/*import {
  TOKEN_HEADER_NAME,
  LOCAL_STORAGE_TOKEN_NAME
} from "../constants/AppConstants";*/
import { trackedAjaxGetJson } from "./AppInsigthsTracker";
import { AjaxResponse } from "rxjs/ajax";

export type RiskApiParams = {
  geo: string;
  time: string;
  realTime: boolean;
};

export type BankApiParams = {
  geo: string;
  time: string;
  realTime: boolean;
};

export type RiskDetailApiParams = {
  geo: string;
  time: string;
  rank: string;
  realTime: boolean;
};

export const getRisk = ({
  geo,
  time,
  realTime
}: RiskApiParams): Observable<AjaxResponse<RiskOverviewData>> => {
  return trackedAjaxGetJson<RiskOverviewData>({
    ...API_SETTINGS,
    url: `${API_ENDPOINT}risk/${geo}/${time}?realtime=${realTime}`
  });
};

export const getRiskDetail = ({
  geo,
  time,
  rank,
  realTime
}: RiskDetailApiParams): Observable<AjaxResponse<RiskDetailData>> => {
  return trackedAjaxGetJson<RiskDetailData>({
    ...API_SETTINGS,
    url: `${API_ENDPOINT}risk/detail/${geo}/${time}/${rank}?realtime=${realTime}`
  });
};
