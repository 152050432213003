import React, { useContext, useState } from "react";
import { SettingsState } from "../types/storesTypes";
import { View, StyleSheet, Platform } from "react-native";
import {
  RankEnum,
  RiskKpiEnum,
  SEMAPHORE_COLORS,
  RankColorEnum
} from "../constants/AppConstants";
import {
  Table,
  Row,
  Cell,
  TableWrapper
} from "@deb-95/react-native-table-component";
import { RiskDetailData, PaymentData } from "../types/apiTypes";
import { ThemeContext } from "../context/ThemeContext";
import {
  shrinkCurrencyFormat,
  formattingCount
} from "../utils/num_formatting/index";
import {
  R_DET_BODY_HEADER_TITLE_ALRT,
  KPI_NAMES,
  R_DET_BODY_HEADER_TITLE_TBC,
  RANK_TRANS
} from "../constants/Dictionary";
import { Avatar } from "react-native-elements";
import AppText from "./text/AppText";
import {
  TABLE_HEADER_HEIGHT,
  DETAIL_TABLE_ROWS_HEIGHT,
  DETAIL_TABLE_FIRST_COLUMN_TEXT_SIZE,
  ROBOTO_BOLD,
  MEDIUM_FONT,
  FONT_SIZE_BASE,
  FONT_SIZE_BASE_LITE
} from "../constants/StyleConstants";
import useScale, { Scales } from "../hooks/useScale";
import { VPayColors } from "../types/appTypes";
import SortableDetailHeader from "./SortableDetailHeader";

type RiskDetailTableProps = {
  riskDetailApi: RiskDetailData;
  settingsState: SettingsState;
  riskR: RankEnum;
};

const riskDetailTableStyles = (colors: VPayColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    container: {
      flex: 1,
      overflow: "hidden"
    },
    childContainer: {
      flexWrap: "nowrap",
      flexDirection: "row"
    },
    headLayout: {
      backgroundColor: colors.tableHeader,
      justifyContent: "center",
      height: moderateScale(...TABLE_HEADER_HEIGHT)
    },
    headTitle: {
      ...MEDIUM_FONT,
      textAlign: "right",
      color: colors.defaultText,
      paddingRight: moderateScale(4, 0.2),
      fontSize: moderateScale(...FONT_SIZE_BASE_LITE)
    },
    headValueLayout: {
      fontFamily: ROBOTO_BOLD,
      alignItems: "flex-end",
      justifyContent: "flex-end",
      backgroundColor: colors.oddHeader,
      paddingBottom: moderateScale(4, 0.2)
    },
    headerValuesEven: {
      backgroundColor: colors.evenHeader
    },
    headerValuesOdd: {
      backgroundColor: colors.oddHeader
    },
    firstColLayout: {
      alignItems: "flex-start",
      justifyContent: "center",
      paddingBottom: 0
    },
    firstCol1: {
      width: moderateScale(35, 0.2),
      alignItems: "center",
      justifyContent: "center"
    },
    tableEven: { backgroundColor: colors.evenValue },
    tableOdd: { backgroundColor: colors.oddValue },
    tableText: {
      ...MEDIUM_FONT,
      color: colors.defaultText,
      fontSize: moderateScale(...FONT_SIZE_BASE)
    },
    subColumn2: {
      flex: 1,
      justifyContent: "center"
    },
    doubleColumnParent: {
      flexDirection: "row"
    },
    cell: { justifyContent: "flex-end", alignItems: "flex-end" },
    cellDefPaddingRx: { paddingRight: moderateScale(4, 0.2) },
    cellLastPaddingRx: { paddingRight: moderateScale(10, 0.2) },
    rowBorder: {
      borderBottomWidth: 1,
      borderBottomColor: colors.tableRowBorder
    },
    heightCell: {
      minHeight: moderateScale(...DETAIL_TABLE_ROWS_HEIGHT)
    }
  });

const RiskDetailTable = ({
  riskDetailApi,
  settingsState,
  riskR
}: RiskDetailTableProps) => {
  const { selectedKpiFilter, selectedLanguage } = settingsState;
  const { colors } = useContext(ThemeContext);
  const scaleFunctions = useScale();
  const { moderateScale } = scaleFunctions;
  const [colOrderedBy, setColOrderedBy] = useState<
    keyof PaymentData | "geoLabel"
  >("geoLabel");
  const [asc, setAsc] = useState<boolean>(true);
  const flexArr = [4.5, 3, 3, 3];
  const isAmount = selectedKpiFilter === RiskKpiEnum.AMOUNT;

  const onTitlePress = (colName: string) => {
    if (colName === colOrderedBy) {
      setAsc(!asc);
    } else {
      setColOrderedBy(colName as keyof PaymentData | "geoLabel");
      setAsc(false);
    }
  };

  const titles = [
    <SortableDetailHeader
      key={"DetailTableTitleLabel"}
      text={""}
      name={"geoLabel"}
      onPress={onTitlePress}
      lastOrd={colOrderedBy}
      asc={asc}
      inputStyle={{
        justifyContent: "flex-start",
        marginLeft: moderateScale(10, 0.2)
      }}
    />,
    <SortableDetailHeader
      key={"DetailTableTitle1"}
      text={KPI_NAMES[selectedKpiFilter][selectedLanguage]}
      name={isAmount ? "amount" : "count"}
      onPress={onTitlePress}
      lastOrd={colOrderedBy}
      asc={asc}
    />,
    <SortableDetailHeader
      key={"DetailTableTitle2"}
      text={R_DET_BODY_HEADER_TITLE_TBC[selectedKpiFilter][selectedLanguage]}
      name={isAmount ? "amountToCheck" : "countToCheck"}
      onPress={onTitlePress}
      lastOrd={colOrderedBy}
      asc={asc}
    />,
    <SortableDetailHeader
      key={"DetailTableTitle3"}
      text={R_DET_BODY_HEADER_TITLE_ALRT[selectedLanguage]}
      name={isAmount ? "amountAlert" : "countAlert"}
      onPress={onTitlePress}
      lastOrd={colOrderedBy}
      asc={asc}
      inputStyle={
        riskDetailTableStyles(colors, scaleFunctions).cellLastPaddingRx
      }
    />
  ];

  const headData = riskDetailApi.table.head.data;

  const headValues = [
    <View
      key={"DetailTableValueAvatar"}
      style={riskDetailTableStyles(colors, scaleFunctions).doubleColumnParent}
    >
      <View
        key={"TableTitle1"}
        style={riskDetailTableStyles(colors, scaleFunctions).firstCol1}
      >
        <Avatar
          rounded
          size={moderateScale(22, 0.2)}
          icon={{
            size: moderateScale(60, 0.2),
            name: "fiber-manual-record",
            color: `${SEMAPHORE_COLORS[RankColorEnum[riskR]]}`,
            type: "material"
          }}
        />
      </View>

      <View
        style={riskDetailTableStyles(colors, scaleFunctions).subColumn2}
        key={"TableTitle2"}
      >
        <AppText
          style={[
            {
              textTransform: "capitalize",
              fontSize: moderateScale(...DETAIL_TABLE_FIRST_COLUMN_TEXT_SIZE)
            }
          ]}
        >
          {RANK_TRANS[riskR][selectedLanguage]}
        </AppText>
      </View>
    </View>,
    isAmount
      ? shrinkCurrencyFormat(headData.amount, selectedLanguage)
      : formattingCount(headData.count, selectedLanguage),
    // TBC
    isAmount
      ? shrinkCurrencyFormat(headData.amountToCheck, selectedLanguage)
      : formattingCount(headData.countToCheck, selectedLanguage),
    // alerts
    isAmount
      ? shrinkCurrencyFormat(headData.amountAlert, selectedLanguage)
      : formattingCount(headData.countAlert, selectedLanguage)
  ];

  const bodyRows: Array<Array<string | number | JSX.Element>> = [];
  const orderedData = [...riskDetailApi.table.items].sort((a, b) => {
    const firstEl =
      colOrderedBy === "geoLabel"
        ? a.geoLabel[selectedLanguage]
        : a.data[colOrderedBy];
    const secondEl =
      colOrderedBy === "geoLabel"
        ? b.geoLabel[selectedLanguage]
        : b.data[colOrderedBy];
    if (asc) {
      return firstEl > secondEl ? 1 : -1;
    } else {
      return firstEl > secondEl ? -1 : 1;
    }
  });

  orderedData.forEach((item, index) => {
    const { data, url, respo } = item;
    const semaphoreColumn = (
      <View
        key={`RowIcon${index}`}
        style={[
          riskDetailTableStyles(colors, scaleFunctions).doubleColumnParent
        ]}
      >
        <View style={riskDetailTableStyles(colors, scaleFunctions).firstCol1}>
          <Avatar rounded size={moderateScale(20, 0.2)} source={{ uri: url }} />
        </View>
        <View style={riskDetailTableStyles(colors, scaleFunctions).subColumn2}>
          <AppText
            style={{
              fontSize: moderateScale(...DETAIL_TABLE_FIRST_COLUMN_TEXT_SIZE)
            }}
          >
            {`${item.geoLabel[selectedLanguage]}`}
          </AppText>
          <AppText
            style={{
              color: colors.colCellSubtitle,
              fontSize: moderateScale(9)
            }}
          >
            {respo}
          </AppText>
        </View>
      </View>
    );
    const countOrAmount = isAmount
      ? shrinkCurrencyFormat(data.amount, selectedLanguage)
      : formattingCount(data.count, selectedLanguage);
    const alerts = isAmount
      ? shrinkCurrencyFormat(data.amountAlert, selectedLanguage)
      : formattingCount(data.countAlert, selectedLanguage);
    const itemTBC = isAmount
      ? shrinkCurrencyFormat(data.amountToCheck, selectedLanguage)
      : formattingCount(data.countToCheck, selectedLanguage);

    bodyRows.push([semaphoreColumn, countOrAmount, itemTBC, alerts]);
  });

  return (
    <View style={riskDetailTableStyles(colors, scaleFunctions).container}>
      <Table style={riskDetailTableStyles(colors, scaleFunctions).container}>
        <Row
          data={titles}
          style={[
            riskDetailTableStyles(colors, scaleFunctions).headLayout,
            riskDetailTableStyles(colors, scaleFunctions).rowBorder
          ]}
          textStyle={[riskDetailTableStyles(colors, scaleFunctions).headTitle]}
          flexArr={flexArr}
        />
        <TableWrapper
          style={[
            riskDetailTableStyles(colors, scaleFunctions).rowBorder,
            riskDetailTableStyles(colors, scaleFunctions).childContainer,
            riskDetailTableStyles(colors, scaleFunctions).heightCell
          ]}
        >
          {headValues.map((headValue, index) => (
            <Cell
              key={"headValueCell" + index}
              data={headValue}
              style={[
                { flex: flexArr[index] },
                riskDetailTableStyles(colors, scaleFunctions).headValueLayout,
                index % 2 === 0
                  ? riskDetailTableStyles(colors, scaleFunctions)
                      .headerValuesEven
                  : riskDetailTableStyles(colors, scaleFunctions)
                      .headerValuesOdd,
                index === 0 &&
                  riskDetailTableStyles(colors, scaleFunctions).firstColLayout
              ]}
              textStyle={[
                riskDetailTableStyles(colors, scaleFunctions).tableText,
                index === headValues.length - 1
                  ? riskDetailTableStyles(colors, scaleFunctions)
                      .cellLastPaddingRx
                  : riskDetailTableStyles(colors, scaleFunctions)
                      .cellDefPaddingRx
              ]}
            />
          ))}
        </TableWrapper>
        <View
          // Typescript is complaining because "auto" does not exist in Native but
          // by checking the platform before using it we can ensure it works.
          style={{
            flex: 1,
            overflow: Platform.OS === "web" ? "auto" : "scroll"
          }}
        >
          {bodyRows.map((row, rowIndex) => (
            <View
              key={"tableWrapper" + rowIndex}
              style={[
                riskDetailTableStyles(colors, scaleFunctions).rowBorder,
                riskDetailTableStyles(colors, scaleFunctions).childContainer
              ]}
            >
              {row.map((cellValue, cellIndex) => (
                <Cell
                  key={"tableWrapperCell" + cellIndex}
                  data={cellValue}
                  style={[
                    { flex: flexArr[cellIndex] },
                    riskDetailTableStyles(colors, scaleFunctions)
                      .headValueLayout,
                    riskDetailTableStyles(colors, scaleFunctions).heightCell,
                    cellIndex % 2 === 0
                      ? riskDetailTableStyles(colors, scaleFunctions).tableEven
                      : riskDetailTableStyles(colors, scaleFunctions).tableOdd,
                    cellIndex === 0 &&
                      riskDetailTableStyles(colors, scaleFunctions)
                        .firstColLayout
                  ]}
                  textStyle={[
                    riskDetailTableStyles(colors, scaleFunctions).tableText,
                    cellIndex === row.length - 1
                      ? riskDetailTableStyles(colors, scaleFunctions)
                          .cellLastPaddingRx
                      : riskDetailTableStyles(colors, scaleFunctions)
                          .cellDefPaddingRx
                  ]}
                />
              ))}
            </View>
          ))}
        </View>
      </Table>
    </View>
  );
};
export default RiskDetailTable;
