import { BottomTabBarButtonProps } from "@react-navigation/bottom-tabs";
import React, { useContext } from "react";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { ThemeContext } from "../context/ThemeContext";

const TabButton: React.FC<BottomTabBarButtonProps> = props => {
  const { colors } = useContext(ThemeContext);
  return (
    <TouchableWithoutFeedback
      {...props}
      style={[
        props.style,
        {
          borderBottomWidth: 4,
          borderBottomColor: props.accessibilityState?.selected
            ? colors.tintColor
            : colors.screenBackgroundColor
        }
      ]}
      containerStyle={{ flex: 1 }}
    />
  );
};

export default TabButton;
