/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import RiskScreen from "../screens/RiskScreen";
import AdvancedScreen from "../screens/AdvancedScreen";
import RiskDetailScreen from "../screens/RiskDetailScreen";
import {
  RISK,
  ADVANCED,
  RISK_DETAIL,
  HOME,
  TAB_DETAIL,
  BANKS,
  RULES
} from "../constants/AppConstants";
import { CloseMenuIcon } from "../components/CloseMenuIcon";
import { MenuIcon } from "../components/MenuIcon";
import { Icon } from "react-native-elements";
import RulesScreen from "../screens/RulesScreen";
import BanksScreen from "../screens/BanksScreen";
import {
  BANKS_HEAD_TITLE,
  RISK_HEAD_TITLE,
  RULES_HEAD_TITLE
} from "../constants/Dictionary";
import useSettingsState from "../hooks/useSettingsState";
import useScale from "../hooks/useScale";
import { ROBOTO, ROBOTO_BOLD } from "../constants/StyleConstants";

const GeneralStack = createStackNavigator();

function getComponent(navigation: any): any {
  switch (navigation.route.name) {
    case RISK:
      return <RiskScreen />;
    case RISK_DETAIL:
      return <RiskDetailScreen />;
    case ADVANCED:
      return <AdvancedScreen />;
    case RULES:
      return <RulesScreen />;
    case BANKS:
      return <BanksScreen />;
  }
}

function getTitle(route: string, selectedLanguage: string): string {
  switch (route) {
    case RISK:
      return RISK_HEAD_TITLE[selectedLanguage];
    case RULES:
      return RULES_HEAD_TITLE[selectedLanguage];
    case BANKS:
      return BANKS_HEAD_TITLE[selectedLanguage];
    default:
      return route;
  }
}

function switchMenuButton(route: string, navigation: any) {
  switch (route) {
    // routes from drawer (secondary)
    case ADVANCED:
      return <CloseMenuIcon navigation={navigation} />;
    // routes from tabs (primary)
    case HOME:
    case RISK:
    default:
      return <MenuIcon navigation={navigation} />;
  }
}

export function generalStackScreen({ navigation, route }: any) {
  const settingsState = useSettingsState();
  const { moderateScale } = useScale();
  return (
    <GeneralStack.Navigator
      headerMode="float"
      screenOptions={{
        headerTitleStyle: {
          textTransform: "uppercase",
          fontFamily: ROBOTO_BOLD,
          fontSize: 13,
          fontWeight: "bold"
        },
        headerTitleAlign: "center",
        headerStyle: { height: 50 },
        headerStatusBarHeight: 0,
        headerBackImage: ({ tintColor }) => (
          <Icon
            name={"arrow-left"}
            type={"simple-line-icon"}
            color={tintColor}
            size={moderateScale(18, 0.2)}
            containerStyle={{ alignSelf: "center" }}
          />
        ),
        headerBackTitleVisible: true,
        headerBackTitleStyle: {
          fontSize: moderateScale(11),
          marginLeft: 1,
          alignSelf: "center",
          fontFamily: ROBOTO
        }
      }}
    >
      <GeneralStack.Screen
        name={route.name}
        component={getComponent}
        options={{
          title:
            settingsState &&
            getTitle(route.name, settingsState.selectedLanguage),
          headerLeft: () => switchMenuButton(route.name, navigation)
        }}
      />
      {route.params?.hasChildren && (
        <GeneralStack.Screen
          name={route.name + TAB_DETAIL}
          component={getComponent}
        />
      )}
    </GeneralStack.Navigator>
  );
}
