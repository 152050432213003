import { View, StyleSheet } from "react-native";
import React from "react";
import Filters from "../components/Filters";
import settingsStore from "../stores/settingsStore";
import LoadingView from "../components/LoadingView";
import RulesService from "../services/RulesService";
import { Response, RulesData } from "../types/apiTypes";
import TimeBar from "../components/TimeBar";
import { ThemeContext } from "../context/ThemeContext";
import useSettingsState from "../hooks/useSettingsState";
import RulesBody from "../components/rules/RulesBody";
import { VPayColors } from "../types/appTypes";

const styles = (colors: VPayColors) =>
  StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.screenBackgroundColor
    }
  });

const RulesScreen = () => {
  const settingsState = useSettingsState(settingsStore.default);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [rulesApi, setRulesApi] = React.useState<RulesData>();
  const { colors } = React.useContext(ThemeContext);

  const handleRulesCall = (rulesResponse: RulesData) => {
    setRulesApi(rulesResponse);
    settingsStore.setLoadingGauge(100);
    setIsLoading(false);
  };

  React.useEffect(() => {
    if (settingsState.selectedGeoFilter && settingsState.selectedTimeFilter) {
      settingsStore.setLoadingGauge(30);
      settingsState.realTime && setIsLoading(true);
      const rulesApiSubscription = RulesService.callRules({
        geo: settingsState.selectedGeoFilter,
        time: settingsState.selectedTimeFilter,
        realTime: settingsState.realTime
      }).subscribe(handleRulesCall);
      return () => {
        rulesApiSubscription.unsubscribe();
      };
    }
  }, [
    settingsState.realTime,
    settingsState.selectedGeoFilter,
    settingsState.selectedTimeFilter
  ]);

  if (isLoading || !settingsState.filters || !rulesApi) {
    return <LoadingView />;
  }

  return (
    <View style={styles(colors).container}>
      <View style={{ paddingBottom: 10 }}>
        <Filters settingsState={settingsState} />
      </View>
      <View style={{ flex: 1 }}>
        <TimeBar settingsState={settingsState} apiData={rulesApi as Response} />
        <RulesBody rulesData={rulesApi.table} settingsState={settingsState} />
      </View>
    </View>
  );
};

export default RulesScreen;
