import React from "react";
import { TouchableOpacity, StyleSheet } from "react-native";
import settingsStore from "../stores/settingsStore";
import { ThemeContext } from "../context/ThemeContext";
import { RiskKpiEnum } from "../constants/AppConstants";
import AppText from "./text/AppText";
import useScale, { Scales } from "../hooks/useScale";
import { VPayColors } from "../types/appTypes";
import { FONT_SIZE_BASE } from "../constants/StyleConstants";

const riskKpiSwitchElementStyle = (
  colors: VPayColors,
  { moderateScale }: Scales
) =>
  StyleSheet.create({
    text: {
      fontSize: moderateScale(...FONT_SIZE_BASE)
    },
    selectedText: {
      fontWeight: "bold",
      color: colors.tintColor
    }
  });

type RiskKpiSwitchElementProps = {
  switchId: RiskKpiEnum;
  selectedKpiFilter: RiskKpiEnum;
  textContent: string;
};

const RiskKpiSwitchElement = ({
  switchId,
  textContent,
  selectedKpiFilter
}: RiskKpiSwitchElementProps) => {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  return (
    <TouchableOpacity
      onPress={() => {
        settingsStore.changeUnitFilter(switchId);
      }}
      style={{
        padding: 3
      }}
    >
      <AppText
        style={[
          riskKpiSwitchElementStyle(colors, scaleFunctions).text,
          selectedKpiFilter === switchId &&
            riskKpiSwitchElementStyle(colors, scaleFunctions).selectedText
        ]}
      >
        {textContent}
      </AppText>
    </TouchableOpacity>
  );
};

export default RiskKpiSwitchElement;
