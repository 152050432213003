import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import TabBarIcon from "../components/TabBarIcon";
import { RISK, RULES, BANKS } from "../constants/AppConstants";
import { ThemeContext } from "../context/ThemeContext";
import { generalStackScreen } from "./GeneralStack";
import useSettingsState from "../hooks/useSettingsState";
import {
  RISK_SCREEN_LABEL,
  RULES_SCREEN_LABEL,
  BANKS_SCREEN_LABEL
} from "../constants/Dictionary";
import useScale from "../hooks/useScale";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import TabButton from "./TabButton";
const BottomTab = createBottomTabNavigator();

export default function TabsComponent() {
  const { colors } = React.useContext(ThemeContext);
  const settingsState = useSettingsState();
  const { moderateScale } = useScale();
  const { bottom } = useSafeAreaInsets();

  return (
    <BottomTab.Navigator
      tabBarOptions={{
        activeBackgroundColor: colors.screenBackgroundColor,
        activeTintColor: colors.tabIconSelected,
        inactiveTintColor: colors.tabIconDefault,
        tabStyle: {
          paddingVertical: moderateScale(4, 0.2)
        },
        style: { height: moderateScale(50, 0.2) + bottom },
        safeAreaInsets: { bottom: bottom ? bottom - 10 : bottom }
      }}
    >
      <BottomTab.Screen
        name={RISK}
        initialParams={{ hasChildren: true }}
        component={generalStackScreen}
        options={{
          tabBarButton: props => <TabButton {...props} />,
          tabBarLabel:
            settingsState && RISK_SCREEN_LABEL[settingsState.selectedLanguage],
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              name={"alert-circle"}
              type={"feather"}
            />
          )
        }}
      />
      <BottomTab.Screen
        name={RULES}
        component={generalStackScreen}
        options={{
          tabBarButton: props => <TabButton {...props} />,
          tabBarLabel:
            settingsState && RULES_SCREEN_LABEL[settingsState.selectedLanguage],
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              name={"tasks"}
              type={"font-awesome-5"}
            />
          )
        }}
      />
      <BottomTab.Screen
        name={BANKS}
        component={generalStackScreen}
        options={{
          tabBarButton: props => <TabButton {...props} />,
          tabBarLabel:
            settingsState && BANKS_SCREEN_LABEL[settingsState.selectedLanguage],
          tabBarIcon: ({ focused }) => (
            <TabBarIcon
              focused={focused}
              name={"university"}
              type={"font-awesome-5"}
            />
          )
        }}
      />
    </BottomTab.Navigator>
  );
}
