import { Observable } from "rxjs";
import { ajax, AjaxRequest, AjaxResponse } from "rxjs/ajax";
import settingsStore from "../stores/settingsStore";

// Here we track all calls passing through this function
export function trackedAjaxGetJson<T>(
  ajaxRequest: unknown
): Observable<AjaxResponse<T>> {
  settingsStore
    .getAppInsights()
    ?.trackPageView({ uri: (ajaxRequest as AjaxRequest).url });
  return ajax<T>(ajaxRequest as AjaxRequest);
}

export function trackedAjaxPostJson(
  ajaxRequest: unknown
): Observable<AjaxResponse<void>> {
  settingsStore
    .getAppInsights()
    ?.trackPageView({ uri: (ajaxRequest as AjaxRequest).url });
  return ajax(ajaxRequest as AjaxRequest);
}
