import React, { useContext } from "react";
import { View, ActivityIndicator } from "react-native";
import { ThemeContext } from "../context/ThemeContext";

const LoadingView = () => {
  const { colors } = useContext(ThemeContext);
  return (
    <View
      style={{
        position: "absolute",
        backgroundColor: colors.loadingScreenBackground,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center"
      }}
    >
      <ActivityIndicator size="large" color="00ff00" />
    </View>
  );
};

export default LoadingView;
