import { ScaleFunctionArgs } from "../hooks/useScale";
import { Language } from "../types/apiTypes";

// SCREENS
export const RISK = "Risk";
export const ADVANCED = "Advanced";
export const HOME = "Home";
export const RISK_DETAIL = "Risk Detail";
export const TAB_DETAIL = " Detail";
export const RULES = "Rules";
export const BANKS = "Banks";

// STATIC LABELS
export const REAL_TIME = "Real Time";

// STATIC OBJECTS
export const LANGUAGES: Language[] = [
  { id: "en", label: { it: "Inglese", en: "English" } },
  { id: "it", label: { it: "Italiano", en: "Italian" } }
];

// SPEEDOMETER CONSTANTS
export const SPEEDOMETER_COLORS = ["#008C45", "#F4F5F0", "#CD212A"];
export const SPEEDOMETER_LABELS = [
  { en: "Very low", it: "Molto basso" },
  { en: "Low", it: "Basso" },
  { en: "Medium", it: "Medio" },
  { en: "High", it: "Elevato" },
  { en: "Very High", it: "Molto alto" }
];

// PROGRESSBAR CONSTANTS
export const PROGRESS_BAR_COLORS = [
  "rgba(26, 147, 82, 0.75)",
  "rgba(13, 225, 140, 0.75)",
  "rgba(245, 205, 39, 0.75)",
  "rgba(255, 141, 0, 0.75)",
  "rgba(249, 29, 45, 0.75)"
];

export const PROGRESS_BAR_COLORS_GRADIENT = [
  "#1A9352",
  "#0DE18C",
  "#F5CD27",
  "#F5CD27",
  "#F5CD27",
  "#FF8D00",
  "#F9541D"
];

export const PROGRESS_BAR_LABELS_COLORS = [
  "rgba(26, 147, 82, 0.5)",
  "rgba(13, 225, 140, 0.5)",
  "rgba(245, 205, 39, 0.5)",
  "rgba(255, 141, 0, 0.5)",
  "rgba(249, 29, 45, 0.5)"
];

export const PROGRESS_BAR_LABELS_COLORS_GRADIENT = [
  "#1A9352",
  "#0DE18C",
  "#F5CD27",
  "#FF8D00",
  "#F52736"
];

export const PROGRESS_BAR_LABELS = [
  { en: "Very low", it: "Molto basso" },
  { en: "Low", it: "Basso" },
  { en: "Medium", it: "Medio" },
  { en: "High", it: "Elevato" },
  { en: "Very High", it: "Molto alto" }
];

// RISK TABLE CONSTANTS
export const SEMAPHORE_COLORS = [
  "#F52736",
  "#FF8D00",
  "#F5CD27",
  "#0DE18C",
  "#1A9352"
];

// UNIT FILTERS IDS
export enum RiskKpiEnum {
  COUNT = "count",
  AMOUNT = "amount"
}

export enum RankEnum {
  VERY_HIGH = "VERY_HIGH",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  VERY_LOW = "VERY_LOW"
}
export enum RankColorEnum {
  VERY_HIGH = 0,
  HIGH = 1,
  MEDIUM = 2,
  LOW = 3,
  VERY_LOW = 4
}

export const CURRENCY_SYM = "€";

export const NO_DATA_NUMBER = "-";

export const TOKEN_HEADER_NAME = "X-Auth-Token";

export const LOCAL_STORAGE_TOKEN_NAME = "VPayToken";
export const LOCAL_STORAGE_LOGGED_NAME = "VPayLoggedUser";

export enum Theme {
  DARK = "dark",
  LIGHT = "light"
}

export const LOCAL_STORAGE_THEME_NAME = "VPThemePref";
export const LOCAL_STORAGE_LANGUAGE_NAME = "VPLanguagePref";

export const TABLE_LINE_HEIGHT_ARGS: ScaleFunctionArgs = [33, 0.3];

export const RULES_MAX_GRAPH_ELEMENTS = 20;
export const BANKS_MAX_GRAPH_ELEMENTS = 15;

export const IS_LOCAL = () => process.env.NODE_ENV !== "production";
