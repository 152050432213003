import React, { useContext } from "react";
import {
  StyleSheet,
  StyleProp,
  View,
  TouchableOpacity,
  ViewStyle,
  TextStyle
} from "react-native";
import { ThemeContext } from "../context/ThemeContext";
import { Icon } from "react-native-elements";
import AppText from "./text/AppText";
import { VPayColors } from "../types/appTypes";
import useScale, { Scales } from "../hooks/useScale";
import {
  BOLD_FONT,
  MEDIUM_FONT,
  FONT_SIZE_BASE_LITE
} from "../constants/StyleConstants";

type TableDescendingSortHeaderProps = {
  containerStyle?: StyleProp<ViewStyle>;
  textStyle?: StyleProp<TextStyle>;
  text: string | number;
  onPress: () => void;
  isSorted: boolean;
  isBold?: boolean;
};

const styles = (colors: VPayColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    headContainer: {
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center"
    },
    headTitle: {
      color: colors.defaultText,
      fontSize: moderateScale(...FONT_SIZE_BASE_LITE),
      paddingLeft: moderateScale(4, 0.2)
    },
    icon: {
      lineHeight: moderateScale(8, 0.2)
    }
  });

const TableDescendingSortHeader = ({
  text,
  onPress,
  isSorted,
  containerStyle,
  textStyle,
  isBold
}: TableDescendingSortHeaderProps) => {
  const { colors } = useContext(ThemeContext);
  const scaleFunctions = useScale();

  const orderingSelectedColumn = (icon: string, color?: string) => {
    return (
      <Icon
        size={scaleFunctions.moderateScale(12, 0.2)}
        name={icon}
        type="octicon"
        color={color || colors.bodyHeaderTitleText}
        iconStyle={styles(colors, scaleFunctions).icon}
      />
    );
  };

  return (
    <TouchableOpacity
      style={StyleSheet.flatten([
        styles(colors, scaleFunctions).headContainer,
        containerStyle
      ])}
      onPress={() => onPress()}
    >
      <View>
        {isSorted ? (
          <View>
            {orderingSelectedColumn("triangle-down", colors.highText)}
          </View>
        ) : (
          <View>{orderingSelectedColumn("triangle-down", undefined)}</View>
        )}
      </View>
      <AppText
        style={[
          styles(colors, scaleFunctions).headTitle,
          textStyle,
          isBold ? BOLD_FONT : MEDIUM_FONT
        ]}
        numberOfLines={1}
        ellipsizeMode="head"
      >
        {text}
      </AppText>
    </TouchableOpacity>
  );
};

export default TableDescendingSortHeader;
